import React, { useState } from "react";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@ui-kit/IconButton";
import MaterilDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@ui-kit/Typography";
import useStyles, { createCss } from "./styles";
import { DialogProps } from "./types";
import { Models } from "@services/api";
import { theme } from "@theme";
import { QuitDialog } from "@components/QuitDialog";
import { useViewport } from "@hooks/useViewport";

const DialogComponent: React.FC<DialogProps> = ({
  children,
  onClose,
  protectQuit,
  title = "",
  loading,
  classes,
  styles,
  tier,
  ...props
}) => {
  const [showQuitProtect, setShowQuitProtect] = useState(false);
  const { isMobile } = useViewport();
  const defaultClasses = useStyles();
  const css = createCss({});

  const quit = () => {
    if (protectQuit) {
      setShowQuitProtect(true);
    } else {
      onClose?.();
    }
  };

  return (
    <MaterilDialog
      onClose={quit}
      fullScreen={isMobile}
      classes={{
        ...classes,
        container: clsx(defaultClasses.container, classes?.container),
        paper: clsx(defaultClasses.paper, classes?.paper),
      }}
      {...props}
    >
      <MuiDialogTitle
        css={[
          css.title,
          styles?.title,
          (tier === Models.SubscriptionTier.Pro ||
            tier === Models.SubscriptionTier.Community) && {
            backgroundColor: theme.palette.text.primary,
            color: "white",
          },
        ]}
        disableTypography
        data-test="dialog-title"
      >
        <Typography className="text" variant="h4">
          <span>{title}</span>
        </Typography>
        {onClose && (
          <IconButton
            aria-label="Close"
            color="text"
            css={[css.close, styles?.close]}
            onClick={quit}
            data-test="close-modal-btn"
          >
            <CloseIcon />
          </IconButton>
        )}
      </MuiDialogTitle>
      <DialogContent
        classes={{
          root: defaultClasses.materialDialogContentRoot,
        }}
        className="dialog-content"
      >
        {showQuitProtect && (
          <QuitDialog
            onClose={() => {
              setShowQuitProtect(false);
            }}
            onQuit={() => {
              onClose?.();
            }}
            tier={tier}
          />
        )}
        {loading && (
          <div className={defaultClasses.progressOverlay}>
            <CircularProgress />
          </div>
        )}
        {children}
      </DialogContent>
    </MaterilDialog>
  );
};

export default DialogComponent;
