import React, { useEffect } from "react";
import cookies from "browser-cookies";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import loadable from "@loadable/component";

import { ROUTES, APP_LOCATION_KEY } from "@constants";
import { usePrevious, useWillMount } from "@hooks";
import { useAppStore } from "@store/AppStore";
import { retry } from "@utils";

const MapView = loadable(() => retry(() => import("./pages/Map")));
const Testpage = loadable(() => retry(() => import("./pages/Test")));
const QuestionnairePage = loadable(() =>
  retry(() => import("./pages/Questionnaire")),
);
const Homepage = loadable(() => retry(() => import("./pages/Main")));
const RegistrationFormsPage = loadable(() =>
  retry(() => import("./pages/RegistrationForms")),
);
const ResetPasswordForm = loadable(() =>
  retry(() => import("./components/ResetPasswordForm")),
);
const ForgotPasswordForm = loadable(() =>
  retry(() => import("./components/ForgotPasswordForm")),
);
const TermsPage = loadable(() => retry(() => import("./pages/Terms")));
const AdminPanel = loadable(() => retry(() => import("./pages/AdminPanel")));
const MyReservations = loadable(() =>
  retry(() => import("./components/WeekendCare/ReservationsHistory")),
);
const PaymentsList = loadable(() =>
  retry(() => import("./components/PaymentsList")),
);
const SignUpForm = loadable(() =>
  retry(() => import("./components/SignUpForm")),
);
const LoginForm = loadable(() => retry(() => import("./components/LoginForm")));
const ParentProfile = loadable(() =>
  retry(() => import("./components/ParentProfile")),
);
const ChildProfilePage = loadable(() =>
  retry(() => import("./pages/ChildProfile")),
);
const ChildrenList = loadable(() =>
  retry(() => import("./components/ChildrenList")),
);
const InvestorsRelations = loadable(() =>
  retry(() => import("./pages/InvestorsRelations")),
);
const SupportDialog = loadable(() =>
  retry(() => import("./components/SupportDialog")),
);
const Messenger = loadable(() => retry(() => import("./pages/Messenger")));
const Dashboard = loadable(() => retry(() => import("./pages/Dashboard")));

export const AppRouting: React.FC = () => {
  const [{ realLocation }, { setRealLocation }] = useAppStore();
  const location = useLocation();
  const history = useHistory();

  const prevLocation = usePrevious(location);

  useWillMount(() => {
    if (!isModal(location)) {
      realLocation.current = location;
    }
  });

  useEffect(() => {
    if (prevLocation && location.pathname !== prevLocation.pathname) {
      if (!isModal(location) && !isModal(prevLocation)) {
        window.scrollTo(0, 0);
      }
    }

    if (!isModal(location)) {
      cookies.set(APP_LOCATION_KEY, JSON.stringify(location));
      setRealLocation(location);
    }
  }, [location]);

  function isModal(loc = location) {
    const modalWindows = [
      "/sign-up",
      "/support",
      "/log-in",
      "/chats",
      "/password-reset",
      "/forgot-password",
      "/my-reservations",
      "/child-profiles",
      "/my-profile",
      "/wallet",
    ];

    return modalWindows.some((url) => new RegExp(url).exec(loc.pathname));
  }

  const modal = isModal();
  const returnPath =
    realLocation.current.pathname + realLocation.current.search;

  return (
    <div>
      <Switch location={modal ? realLocation.current : location}>
        <Route exact path="/" component={Homepage} />
        <Route path="/map/:facilityId?" component={MapView} />
        <Route path="/admin" component={AdminPanel} />
        <Route path={ROUTES.QUESTIONNAIRE} component={QuestionnairePage} />
        <Route path={ROUTES.INVESTORS} component={InvestorsRelations} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/terms" component={TermsPage} />
        <Route
          path={`${ROUTES.REGISTRATION_FORMS}/:countryId?/:jurisdictionId?`}
          component={RegistrationFormsPage}
        />
        <Route path="/error-test" component={Testpage} />
        <Redirect to="/" />
      </Switch>

      {modal ? (
        <>
          <Route exact path="/sign-up" component={SignUpForm} />
          <Route exact path="/log-in" component={LoginForm} />
          <Route exact path="/support" component={SupportDialog} />
          <Route exact path="/chats" component={Messenger} />
          <Route
            exact
            path="/my-reservations"
            render={(props) => (
              <MyReservations
                onClose={() => history.replace(returnPath)}
                {...props}
              />
            )}
          />
          <Route exact path="/child-profiles" component={ChildrenList} />
          <Route path="/child-profiles/:childId" component={ChildProfilePage} />
          <Route
            exact
            path="/my-profile"
            render={(props) => (
              <ParentProfile
                onClose={() => history.replace(returnPath)}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/wallet"
            render={(props) => (
              <PaymentsList
                onClose={() => history.replace(returnPath)}
                {...props}
              />
            )}
          />
          <Route exact path="/password-reset" component={ResetPasswordForm} />
          <Route
            exact
            path="/forgot-password"
            render={(props) => (
              <ForgotPasswordForm
                onClose={() => history.replace(returnPath)}
                {...props}
              />
            )}
          />
        </>
      ) : null}
    </div>
  );
};
