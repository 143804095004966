import { denormalizeAge } from "@helpers/ages-helper";
import { ageUnitsMap } from "@constants/age-units";
import { Models } from "@services/api";

export default (
  fromAge?: number | null,
  fromUnit?: Models.AgeUnit | null,
  toAge?: number | null,
  toUnit?: Models.AgeUnit | null,
): string => {
  if ((!fromAge && fromAge !== 0) || !fromUnit) {
    return "";
  }

  let ageRange = `${denormalizeAge(fromAge, fromUnit)}`;

  if (
    !(
      toAge &&
      (fromUnit === toUnit ||
        (fromUnit === Models.AgeUnit.Years &&
          toUnit === Models.AgeUnit.YearsMonths))
    )
  ) {
    ageRange += ageUnitsMap[fromUnit].title;
  }

  if (toAge && toAge >= 3000000) {
    ageRange += `${ageUnitsMap[fromUnit].title}+`;
    return ageRange;
  }

  if (toAge && toUnit) {
    ageRange += ` - ${denormalizeAge(toAge, toUnit)}${
      ageUnitsMap[toUnit].title
    }`;
  }

  return ageRange;
};
