import log from "loglevel";

export interface EventArgs {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

const event = (payload: EventArgs) => {
  window.gtag?.("event", payload.action, {
    event_category: payload.category,
    event_label: payload.label,
    value: payload.value,
  });
};

export function ReactGAEventWait(payload: EventArgs): void {
  if (
    typeof window === "undefined" ||
    typeof window.dataLayer === "undefined"
  ) {
    return;
  }

  if (typeof window.gtag !== "undefined") {
    event(payload);

    return;
  }

  const cancel = window.setInterval(() => {
    log.info("Retrying to send GA event...");

    if (typeof window.gtag !== "undefined") {
      event(payload);

      log.info("GA event send retry successful!");
      window.clearInterval(cancel);
    }
  }, 500);
}
