import isNil from "lodash/isNil";
import { Models } from "@services/api";
import { must } from "@utils/must";
import { getAge } from "@helpers/get-age-from-date";

export class Child {
  private _firstName: string | null = null;
  private _lastName: string | null = null;
  private _gender: Models.Gender | null = null;
  private _birthday: string | null = null;
  private _documents: Models.ChildDocuments | null = null;
  private _photo: Models.UploadedFile | null = null;

  get firstName(): string | null {
    return this._firstName;
  }
  set firstName(v: string | null | undefined) {
    this._firstName = !isNil(v) ? v : null;
  }

  get lastName(): string | null {
    return this._lastName;
  }
  set lastName(v: string | null | undefined) {
    this._lastName = !isNil(v) ? v : null;
  }

  get gender(): Models.Gender | null {
    return this._gender;
  }
  set gender(v: Models.Gender | null | undefined) {
    this._gender = !isNil(v) ? v : null;
  }

  get birthday(): string | null {
    return this._birthday;
  }
  set birthday(v: string | null | undefined) {
    this._birthday = !isNil(v) ? v : null;
  }

  get documents(): Models.ChildDocuments | null {
    return this._documents;
  }

  get photo(): Models.UploadedFile | null {
    return this._photo;
  }

  public getFullName = (): string => {
    return [this.firstName, " ", this.lastName].filter(Boolean).join("");
  };

  public getAge = (): string | null => {
    if (!this.birthday) {
      return null;
    }
    return getAge(this.birthday)?.str || null;
  };

  public toDto(): Models.Child {
    return {
      firstName: must(this.firstName),
      lastName: must(this.lastName),
      gender: must(this.gender),
      birthday: must(this.birthday),
    };
  }

  static fromDto(dto: Models.Child): Child {
    const child = new Child();

    child.firstName = dto.firstName;
    child.lastName = dto.lastName;
    child.birthday = dto.birthday;
    child.gender = dto.gender;
    child._documents = dto.documents || null;
    child._photo = dto.photo || null;

    return child;
  }
}
