import React, { useEffect } from "react";
import * as FullStory from "@fullstory/browser";

import { globalConfig } from "@constants";
import { useUserStore } from "@store/UserStore";
import { getUserFullName } from "@selectors";

const FullstoryTracker: React.FC = () => {
  if (!globalConfig.FULLSTORY_ORG_ID) {
    return null;
  }

  const [{ user }] = useUserStore();

  useEffect(() => {
    setIdentify();
  }, []);

  useEffect(() => {
    setIdentify();
  }, [user]);

  function setIdentify() {
    if (user) {
      FullStory.identify(user.id.toString(), {
        email: user.email,
        name: getUserFullName(user),
      });
    } else {
      FullStory.anonymize();
    }
  }

  return null;
};

export default FullstoryTracker;
