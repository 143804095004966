import React from "react";

import { AppStore } from "./AppStore";
import { UserStore } from "./UserStore";
import { DeepDivePanelStore } from "../hooks/useDeepDivePanelStore";
import { MapStore } from "./MapStore";
import { Viewport } from "../hooks/useViewport";

export type RootStore = Partial<{
  user: Partial<UserStore>;
  app: Partial<AppStore>;
  ddp: Partial<DeepDivePanelStore>;
  map: Partial<MapStore>;
  viewport: Viewport;
}>;

const RootStoreContext = React.createContext<RootStore | null>(null);

export const RootStoreProvider: React.FC<{
  initialState?: Partial<RootStore>;
}> = ({ children, initialState = {} }) => {
  const [state, setState] = React.useState<RootStore | null>(initialState);

  React.useEffect(() => {
    setState(null);
  }, []);

  return (
    <RootStoreContext.Provider value={state}>
      {children}
    </RootStoreContext.Provider>
  );
};

export const useRootStore = (): RootStore | null => {
  return React.useContext(RootStoreContext);
};
