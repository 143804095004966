import { createUseStyles } from "react-jss";
import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes, DialogProps } from "./types";

const styles = (theme: ITheme) => ({
  paper: {
    [theme.mediaRequests.notMobile]: {
      width: "fit-content",
    },
  },

  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },

  materialDialogContentRoot: {
    minHeight: 100,
    padding: "16px 24px 24px",
    position: "relative",
  },

  progressOverlay: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, .8)",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 99,
  },

  closeButton: {
    color: "inherit",
    position: "absolute",
    right: theme.spacing(),
    top: "50%",
    transform: "translateY(-50%)",
  },
});

export default createUseStyles(styles, { name: "CustomDialog" });

export const createCss = (
  props: Partial<DialogProps>,
): Record<Classes, Interpolation<ITheme>> => ({
  title: (theme) => ({
    backgroundColor: theme.palette.yellow.main,
    position: "relative",

    "& .text": {
      display: "inline-flex",
      justifyContent: "center",
      width: "calc(100% - 30px)",

      "& span": {
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },

    "& h6": {
      fontSize: "1.4rem",
      overflow: "hidden",
      paddingRight: 30,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),

  close: (theme) => ({
    color: "inherit",
    position: "absolute",
    right: theme.spacing(),
    top: "50%",
    transform: "translateY(-50%)",
  }),
});
