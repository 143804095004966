import React from "react";

import DividerImg from "@images/divider_shadow.png";

export const ShadowDivider: React.FC = () => {
  return (
    <div>
      <img
        src={DividerImg}
        alt="divider"
        css={{
          display: "block",
          margin: "30px auto 15px",
          width: 300,
        }}
      />
    </div>
  );
};
