import { AmplitudeClient } from "amplitude-js";

import isBrowser from "@helpers/is-browser";
import { globalConfig } from "@constants";

const getAmplitudeInstance = () => {
  const amplitude = require("amplitude-js");
  return amplitude.getInstance() as AmplitudeClient;
};

export const Amplitude =
  !isBrowser() || !globalConfig.AMPLITUDE_KEY
    ? undefined
    : getAmplitudeInstance();

Amplitude?.init(globalConfig.AMPLITUDE_KEY, undefined, {
  includeUtm: true,
});
