import { Models } from "@services/api";

import { FacilityBase } from "./FacilityBase";

export class FacilityCard extends FacilityBase {
  static fromDto(dto: Models.FacilityCard): FacilityCard {
    const facility: FacilityCard = FacilityBase.fromDto(dto);

    return facility;
  }
}
