import React, { useCallback, useEffect } from "react";
import cookies from "browser-cookies";

import { IS_TEST, TOKEN_KEY } from "@constants";
import {
  USER_API,
  Models,
  FACILITY_CARD_API,
  FACILITY_API,
  PAYMENTS_API,
} from "@services/api";
import { ReactGAEventWait } from "@helpers/ga";
import { useRootStore } from "@store/RootStore";

export type UserStore = {
  user: Models.User | null;
  favorites: Models.FacilityCard[];
  claimed: Models.FacilityCard[];
  paymentSource: Models.PaymentsSource | null;
};

export type UserStoreActions = {
  setUser: (user: Models.User | null) => void;
  register: (payload: Models.UsersRegister) => Promise<Models.User>;
  login: (credentials: Models.UsersLogin) => Promise<void>;
  logout: () => void;
  updateUser: (user: Models.User) => Promise<void>;
  confirmPin: (pin: string) => Promise<void>;
  getUserData: () => Promise<
    [Models.User, Models.FacilityCard[], Models.FacilityCard[]]
  >;
  getPaymentMethod: () => Promise<Models.PaymentsSource>;
  savePaymentSource: (
    source?: Models.PaymentsSource,
  ) => Promise<Models.PaymentsSource | undefined>;
  removeFavoriteFacility: (id: string) => Promise<void>;
  addFavoriteFacility: (id: string) => Promise<void>;
  setPaymentSource: (sourceData: Models.PaymentsSource | null) => void;
  resetPassword: (data: { password: string; token: string }) => Promise<void>;
};

const defaultState: UserStore = {
  claimed: [],
  favorites: [],
  paymentSource: null,
  user: null,
};

const UserStoreContext = React.createContext<
  [UserStore, UserStoreActions] | null
>(null);

export const UserStoreProvider: React.FC<{
  initialState?: Partial<UserStore>;
}> = ({ children, initialState = {} }) => {
  const root = useRootStore();
  const [state, setState] = React.useState<UserStore>(() => {
    return {
      ...defaultState,
      ...(root?.user || {}),
      ...initialState,
    };
  });

  useEffect(() => {
    if (!state.user) {
      return;
    }

    if (!IS_TEST) {
      void (async () => {
        const [favoritesResponse, claimedResponse] = await Promise.all([
          FACILITY_CARD_API.facilityCardsIndex({
            folder: "favorites",
          }),
          FACILITY_CARD_API.facilityCardsIndex({
            accountIds: [-1],
          }),
        ]);

        setState((prev) => ({
          ...prev,
          claimed: claimedResponse.data.data,
          favorites: favoritesResponse.data.data,
        }));
      })();
    }
  }, []);

  const setUser = (user: Models.User | null) => {
    setState((prev) => ({
      ...prev,
      user,
    }));
  };

  const updateUser = async (user: Models.User) => {
    const response = await USER_API.usersUpdateById({
      id: user.id,
      user,
    });

    setUser(response.data);
  };

  const confirmPin = async (pin: string) => {
    const { data } = await USER_API.usersConfirmComplete({
      usersConfirmComplete: {
        pin,
      },
    });

    setUser(data);
  };

  const register = useCallback(
    async (payload: Models.UsersRegister) => {
      const {
        data: { token },
      } = await USER_API.usersRegister({
        usersRegister: payload,
      });

      cookies.set(TOKEN_KEY, token);

      const [user] = await getUserData();

      return user;
    },
    [setState],
  );

  const login = async (credentials: Models.UsersLogin) => {
    const {
      data: { token },
    } = await USER_API.usersLogin({
      usersLogin: credentials,
    });

    cookies.set(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_KEY, token);

    const [userData] = await getUserData();

    ReactGAEventWait({
      category: "User",
      action: "SignedIn",
      label: `${userData.id}`,
    });
  };

  const logout = () => {
    ReactGAEventWait({
      category: "User",
      action: "Signed out",
      label: `${state.user?.id}`,
    });

    setState((prev) => ({
      ...prev,
      claimed: [],
      favorites: [],
      paymentSource: null,
      user: null,
    }));
    cookies.erase(TOKEN_KEY);
    localStorage.removeItem(TOKEN_KEY);
  };

  const getUserData = async (): Promise<
    [Models.User, Models.FacilityCard[], Models.FacilityCard[]]
  > => {
    const [user, favoritesResponse, claimedResponse] = await Promise.all([
      USER_API.usersGetById({
        id: -1,
      }),
      FACILITY_CARD_API.facilityCardsIndex({
        folder: "favorites",
      }),
      FACILITY_CARD_API.facilityCardsIndex({
        accountIds: [-1],
      }),
    ]);

    setState((prev) => ({
      ...prev,
      claimed: claimedResponse.data.data,
      favorites: favoritesResponse.data.data,
      user: user.data,
    }));

    return [user.data, favoritesResponse.data.data, claimedResponse.data.data];
  };

  const setPaymentSource = (source: Models.PaymentsSource | null) => {
    setState((prev) => ({
      ...prev,
      paymentSource: source,
    }));
  };

  const getPaymentMethod = async () => {
    const { data } = await PAYMENTS_API.paymentsByIdGet();
    setPaymentSource(data);
    return data;
  };

  const savePaymentSource = async (src?: Models.PaymentsSource) => {
    const source = src || state.paymentSource;
    if (!source) {
      return;
    }
    const { data } = await PAYMENTS_API.paymentsByIdUpdate({
      paymentsSource: source,
    });
    setPaymentSource(data);

    return data;
  };

  const removeFavoriteFacility = async (id: string) => {
    await FACILITY_API.facilityBookmarksByIdDelete({
      id,
    });

    const favoritesCopy = state.favorites.slice();
    favoritesCopy.splice(
      state.favorites.findIndex((f) => f.id === id),
      1,
    );

    setState((prev) => ({
      ...prev,
      favorites: favoritesCopy,
    }));
  };

  const addFavoriteFacility = async (id: string) => {
    await FACILITY_API.facilityBookmarksByIdCreate({
      id,
    });

    const {
      data: { data },
    } = await FACILITY_CARD_API.facilityCardsIndex({
      folder: "favorites",
    });

    setState((prev) => ({
      ...prev,
      favorites: data,
    }));
  };

  const resetPassword = async (data: { password: string; token: string }) => {
    const {
      data: { token },
    } = await USER_API.usersPasswordResetComplete({
      usersPasswordResetComplete: data,
    });

    cookies.set(TOKEN_KEY, token);

    const [userData] = await getUserData();

    ReactGAEventWait({
      category: "PasswordReset",
      action: "Finished",
      label: `${userData.id}`,
    });
  };

  return (
    <UserStoreContext.Provider
      value={[
        state,
        {
          addFavoriteFacility,
          confirmPin,
          getPaymentMethod,
          getUserData,
          login,
          logout,
          removeFavoriteFacility,
          resetPassword,
          savePaymentSource,
          setPaymentSource,
          setUser,
          updateUser,
          register,
        },
      ]}
    >
      {children}
    </UserStoreContext.Provider>
  );
};

export const useUserStore = (): [UserStore, UserStoreActions] => {
  const store = React.useContext(UserStoreContext);

  if (!store) {
    throw new Error(
      "useUserStore must be used within a UserStoreContextProvider",
    );
  }

  return store;
};
