import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: {
    minWidth: 300,
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },
  },
});
