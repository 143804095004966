import React from "react";

import { ButtonIconProps } from "./types";
import { useCss } from "./styles";

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  position,
  size,
  children,
}) => {
  const css = useCss({
    position,
    size,
  });

  return <div css={css.root}>{children}</div>;
};
