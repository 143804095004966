import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    "&.success": {
      "& $content": {
        backgroundColor: theme.palette.success.main,
      },
    },

    "&.error": {
      "& $content": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },

  content: {},
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "CustomSnackbar" },
);
