import React, { useCallback, useRef, useEffect } from "react";
import { LoadableComponent } from "@loadable/component";

import { Models, FACILITY_API, BOOKING_API } from "@services/api";

export type DeepDivePanelStore = {
  facility: Models.Facility;
  bookingConfig?: Models.BookingConfig;
};

type DeepDivePanelComponents =
  | "EditDocumentsPage"
  | "EditMealsAndNutrition"
  | "EditOperationalDetails"
  | "EditTuitionAndFees"
  | "EditFeaturesAndAmenities"
  | "EditProgramsAndServices"
  | "EditFacilityInfoForm";

export type DeepDivePanelComponentsMap = {
  [key in DeepDivePanelComponents]: LoadableComponent<any>;
};

const defaultState: Partial<DeepDivePanelStore> = {};

interface DeepDivePanelStoreActions {
  setFacility: (facility: Models.Facility) => void;
  saveFacility: (data?: Models.Facility) => Promise<void>;
  reloadFacility: () => void;
}

const DeepDivePanelStoreContext = React.createContext<
  | [
      DeepDivePanelStore & {
        Components?: DeepDivePanelComponentsMap;
      },
      DeepDivePanelStoreActions,
    ]
  | null
>(null);

export const DeepDivePanelStoreProvider: React.FC<{
  initialState: DeepDivePanelStore;
  components?: DeepDivePanelComponentsMap;
}> = ({ children, initialState, components }) => {
  const [state, setState] = React.useState<DeepDivePanelStore>({
    ...defaultState,
    ...initialState,
  });
  const Components = useRef(components);

  const setFacility = useCallback(
    (facility: Models.Facility) => {
      setState((prev) => ({
        ...prev,
        facility,
      }));
    },
    [setState],
  );

  const saveFacility = useCallback(
    async (data: Models.Facility = state.facility) => {
      const response = await FACILITY_API.facilitiesByIdUpdate({
        facility: data,
        id: data.id,
      });

      setFacility(response.data);
    },
    [setFacility],
  );

  const reloadFacility = useCallback(async () => {
    const response = await FACILITY_API.facilitiesByIdGet({
      id: state.facility.id,
    });
    setFacility(response.data);
  }, [setFacility]);

  useEffect(() => {
    if (state.facility.id === state.bookingConfig?.id) {
      return;
    }
    if (state.facility.id) {
      void BOOKING_API.bookingConfigsByIdGet({
        id: state.facility.id,
      }).then(({ data }) => {
        setState((prev) => ({
          ...prev,
          bookingConfig: data,
        }));
      });
    }
  }, [state.facility.id]);

  return (
    <DeepDivePanelStoreContext.Provider
      value={[
        {
          ...state,
          Components: Components.current,
        },
        {
          reloadFacility,
          saveFacility,
          setFacility,
        },
      ]}
    >
      {children}
    </DeepDivePanelStoreContext.Provider>
  );
};

export const useDeepDivePanelStore = (): [
  DeepDivePanelStore & {
    Components?: DeepDivePanelComponentsMap;
  },
  DeepDivePanelStoreActions,
] => {
  const store = React.useContext(DeepDivePanelStoreContext);

  if (!store) {
    throw new Error(
      "useDeepDivePanelStore must be used within a DeepDivePanelStoreProvider",
    );
  }

  return store;
};
