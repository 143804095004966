import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    whiteSpace: "pre-wrap",

    "&.italic": {
      fontStyle: "italic",
    },

    "&.bolded": {
      fontWeight: 600,
    },

    "&.paragraphTop": {
      marginTop: 16,
    },

    "&.gap": {
      marginBottom: 32,
    },

    "&.success": {
      color: theme.palette.success.main,
    },

    "&.error": {
      color: theme.palette.error.main,
    },

    "&.warning": {
      color: theme.palette.warning.main,
    },

    "&.pink": {
      color: theme.palette.pink.main,
    },
  }),
});
