import isBrowser from "@helpers/is-browser";

export const TOKEN_KEY = "authenticator";
export const APP_LOCATION_KEY = "APP_LOCATION";

export const IS_TEST =
  typeof process !== "undefined" &&
  process.env &&
  process.env.NODE_ENV === "test";

export const globalConfig =
  isBrowser() && !IS_TEST
    ? (window as any).globalConfig
    : {
        AMPLITUDE_KEY: process.env.AMPLITUDE_KEY,
        API_URL: process.env.API_URL,
        APPLICATION_INSIGHTS_CLIENT_CLOUD_ROLE_NAME:
          process.env.APPLICATION_INSIGHTS_CLIENT_CLOUD_ROLE_NAME,
        APPLICATION_INSIGHTS_KEY: process.env.APPLICATION_INSIGHTS_KEY,
        APPLICATION_INSIGHTS_SERVER_CLOUD_ROLE_NAME:
          process.env.APPLICATION_INSIGHTS_SERVER_CLOUD_ROLE_NAME,
        CLOUDINARY_API_KEY: process.env.CLOUDINARY_API_KEY,
        CLOUDINARY_CLOUD_NAME: process.env.CLOUDINARY_CLOUD_NAME,
        CLOUDINARY_UNSIGNED_KEY: process.env.CLOUDINARY_UNSIGNED_KEY,
        DEBUG: process.env.DEBUG,
        ENVIRONMENT: process.env.ENVIRONMENT,
        FULLSTORY_ORG_ID: process.env.FULLSTORY_ORG_ID,
        GA_ID: process.env.GA_ID,
        MAPBOX_PUBLIC_KEY: process.env.MAPBOX_PUBLIC_KEY,
        NODE_ENV: process.env.NODE_ENV,
        PORT: process.env.PORT,
        PUBLIC_URL: process.env.PUBLIC_URL,
        RELEASE: process.env.RELEASE,
        SENTRY_KEY: process.env.SENTRY_KEY,
        SENTRY_ORGANIZATION: process.env.SENTRY_ORGANIZATION,
        STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
        UI_URL: process.env.UI_URL,
      };

export const ROOT_API_V1 = `${globalConfig.API_URL}/api/v1`;
export const MAPBOX_MAP_STYLE_URL = `mapbox://styles/gospacey/ck6td7n6x60qm1iqyoegquuod${
  globalConfig.DEBUG ? "/draft" : ""
}?optimize=true`;
export const CLOUDINARY_URLS = {
  CONTEXT: `https://api.cloudinary.com/v1_1/${globalConfig.CLOUDINARY_CLOUD_NAME}/auto/context`,
  UPLOAD: `https://api.cloudinary.com/v1_1/${globalConfig.CLOUDINARY_CLOUD_NAME}/auto/upload`,
};

export const ROUTES = {
  ACTIVITIES: "/my-reservations",
  CHILDREN_LIST: "/child-profiles",
  FORGOT_PASSWORD_PAGE: "/forgot-password",
  RESET_PASSWORD_PAGE: "/password-reset",
  HOMEPAGE: "/",
  INVESTORS: "/investor-relations",
  LOGIN_FORM: "/log-in",
  MAP: "/map",
  OUR_STORY: "/our-story",
  PRESS_KIT: "/media-relations",
  PROFILE: "/my-profile",
  QUESTIONNAIRE: "/daycare-questionnaire",
  REPORT: "/report/:facilityId",
  SIGNUP_FORM: "/sign-up",
  TERMS: "/terms",
  WALLET: "/wallet",
  REGISTRATION_FORMS: "/registration-forms",

  isMapView: (pathname: string): boolean => pathname?.startsWith("/map"),
};
