import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

type DayOfWeek = {
  id: Models.WeekDay;
  title: string;
  order: number;
  shortTitle: string;
};

export const DAYS_OF_WEEK: DayOfWeek[] = [
  {
    id: Models.WeekDay.Monday,
    order: 1,
    shortTitle: "Mon",
    title: "Monday",
  },
  {
    id: Models.WeekDay.Tuesday,
    order: 2,
    shortTitle: "Tue",
    title: "Tuesday",
  },
  {
    id: Models.WeekDay.Wednesday,
    order: 3,
    shortTitle: "Wed",
    title: "Wednesday",
  },
  {
    id: Models.WeekDay.Thursday,
    order: 4,
    shortTitle: "Thu",
    title: "Thursday",
  },
  {
    id: Models.WeekDay.Friday,
    order: 5,
    shortTitle: "Fri",
    title: "Friday",
  },
  {
    id: Models.WeekDay.Saturday,
    order: 6,
    shortTitle: "Sat",
    title: "Saturday",
  },
  {
    id: Models.WeekDay.Sunday,
    order: 7,
    shortTitle: "Sun",
    title: "Sunday",
  },
];

export const daysMap = keyBy(DAYS_OF_WEEK, "id");
