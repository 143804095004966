import { Models } from "@services/api";
import { FacilityCard } from "./FacilityCard";
import { Child } from "./Child";

export class BookingRequest {
  private _id: number;
  private _publicId: string;
  private _isCancellable: boolean = true;
  private _cancelledAt?: string;
  private _createdAt: string;
  private _facilityCard: FacilityCard;
  private _parent: Models.User | null = null;
  private _child: Child;
  private _additionalInfo?: string;
  private _medicalInformation?: string;
  private _bookingSlot: Models.BookingConfigSuggestedShift;
  private _chargedAmount: number = 0;

  constructor(
    id: number,
    publicId: string,
    createdAt: string,
    facilityCard: FacilityCard,
    child: Child,
    bookingSlot: Models.BookingConfigSuggestedShift,
  ) {
    this._id = id;
    this._publicId = publicId;
    this._createdAt = createdAt;
    this._facilityCard = facilityCard;
    this._child = child;
    this._bookingSlot = bookingSlot;
  }

  get id(): number {
    return this._id;
  }

  get publicId(): string {
    return this._publicId;
  }

  get isCancellable(): boolean | null {
    return this._isCancellable;
  }

  get cancelledAt(): string | undefined {
    return this._cancelledAt;
  }

  get chargedAmount(): number {
    return this._chargedAmount;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  get facilityCard(): FacilityCard {
    return this._facilityCard;
  }

  get parent(): Models.User | null {
    return this._parent;
  }

  get child(): Child {
    return this._child;
  }

  get medicalInformation(): string | undefined {
    return this._medicalInformation;
  }

  get additionalInfo(): string | undefined {
    return this._additionalInfo;
  }

  get bookingSlot(): Models.BookingConfigSuggestedShift {
    return this._bookingSlot;
  }

  public isCancelled = () => {
    if (!this.cancelledAt || this.cancelledAt === "0001-01-01T00:00:00Z") {
      return false;
    }
    return true;
  };

  static fromDto(dto: Models.BookingRequest): BookingRequest {
    const bookingRequest = new BookingRequest(
      dto.id,
      dto.publicId,
      dto.createdAt,
      FacilityCard.fromDto(dto.facilityCard),
      Child.fromDto(dto.child),
      dto.bookingSlot,
    );

    bookingRequest._isCancellable = dto.isCancellable;
    bookingRequest._cancelledAt = dto.cancelledAt;
    bookingRequest._parent = dto.parent || null;
    bookingRequest._additionalInfo = dto.additionalInfo;
    bookingRequest._medicalInformation = dto.medicalInformation;
    bookingRequest._chargedAmount = dto.chargedAmount;

    return bookingRequest;
  }
}
