import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useUserStore } from "@store/UserStore";
import { Amplitude } from "@services/amplitude";

export const AmplitudeTracker: React.FC = () => {
  const location = useLocation();
  const [{ user }] = useUserStore();

  useEffect(() => {
    if (user) {
      Amplitude?.setUserId(user.id.toString());
      Amplitude?.setUserProperties({
        firstName: user.firstName,
        lastName: user.lastName,
        accountId: user.account.id,
        zip: user.addressZip,
        email: user.email,
        phone: user.phone,
        role: user.role,
      });
    } else {
      Amplitude?.setUserId(null);
    }
  }, [user]);

  useEffect(() => {
    const { pathname, search, hash } = location;

    Amplitude?.logEvent("Page View", {
      page_path: pathname + search + hash,
    });
  }, [location]);

  return null;
};
