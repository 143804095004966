export const getErrorStub = (): string => {
  return `
    <!doctype html>
    <html lang="en">
      <head>
        <link rel="shortcut icon" href="/favicon.png" />
        <style>
          * {
            box-sizing: border-box;
          }

          body {
            color: #1B1B1E;
            margin: 0;
            padding: 0;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
          }

          .container {
            align-items: center;
            display: flex;
            height: 100vh;
            justify-content: center;
            padding: 15px;
            max-width: 900px;
            margin: 0 auto;
          }

          h4 {
            font-size: 2.125rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 700;
            line-height: 1.17;
            letter-spacing: 0.00735em;
          }

          p {
            font-size: 1.25rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            letter-spacing: 0.00735em;
          }
        </style>
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
        />
        <meta name="theme-color" content="#7d7ecc">
      </head>
      <body>
        <div class="container">
          <div>
            <h4>
              An unexpected error has occurred.
            </h4>
            <p>
              We are aware of the problem and are working to fix it.<br/>
              Check back soon or try to reload the page.
            </p>
            <p>
              Our support team can be reached at<br/>
              <a href="mailto:support@parentdayout.com">support@parentdayout.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>
  `;
};
