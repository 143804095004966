import React from "react";
import MaterialSnackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import clsx from "clsx";

import useStyles from "./styles";

interface IProps extends SnackbarProps {
  type: "success" | "error";
}

const Snackbar: React.FC<IProps> = ({ type, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialSnackbar
      {...props}
      classes={{
        root: clsx(classes.root, type),
      }}
      ContentProps={{
        ...props.ContentProps,
        classes: {
          root: classes.content,
        },
      }}
    />
  );
};

export default Snackbar;
