import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { Palette, PaletteColor } from "@material-ui/core/styles/createPalette";

export const TABLET_BREAKPOINT = 768;

export interface ITheme extends Theme {
  palette: Palette & {
    success: PaletteColor;
    warning: PaletteColor;
    pink: PaletteColor;
    yellow: PaletteColor;
  };
  mediaRequests: {
    mobile: string;
    desktop: string;
    tablet: string;
    notMobile: string;
    notDesktop: string;
  };
}

const theme: ITheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  palette: {
    background: {
      default: "#f9f8f7",
      paper: "#f9f8f7",
    },
    error: { main: "#e70600" },
    primary: { main: "#0000ff" },
    secondary: { main: "#0000ff" },
    text: {
      primary: "#1B1B1E",
    },
    warning: { main: "#E69E14" },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
}) as ITheme;

theme.palette.success = theme.palette.augmentColor({ main: "#1fda53" });
theme.palette.pink = theme.palette.augmentColor({ main: "#ff0083" });
theme.palette.yellow = theme.palette.augmentColor({ main: "#f6ce0e" });

theme.mediaRequests = {
  desktop: `@media (min-width: ${1024}px)`,
  mobile: `@media (max-width: ${TABLET_BREAKPOINT - 1}px)`,
  notDesktop: `@media (max-width: ${1023}px)`,
  notMobile: `@media (min-width: ${TABLET_BREAKPOINT}px)`,
  tablet: `@media (min-width: ${TABLET_BREAKPOINT}px) and (max-width: ${1023}px)`,
};

theme.typography.body2 = {
  fontSize: 14,
};

theme.typography.h1 = {
  fontSize: 48,
  fontWeight: 700,

  [theme.mediaRequests.mobile]: {
    fontSize: 32,
  },
};

theme.typography.h2 = {
  fontSize: 36,
  fontWeight: 700,

  [theme.mediaRequests.mobile]: {
    fontSize: 26,
  },
};

theme.typography.h3 = {
  fontSize: 28,
  fontWeight: 700,

  [theme.mediaRequests.mobile]: {
    fontSize: 22,
  },
};

theme.typography.h4 = {
  fontSize: 20,
  fontWeight: 600,
};

theme.typography.h5 = {
  fontSize: 18,
  fontWeight: 600,
};

theme.typography.h6 = {
  fontSize: 16,
  fontWeight: 400,
};

export { theme };
