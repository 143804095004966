import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import track, { useTracking } from "react-tracking";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios, { AxiosRequestConfig } from "axios";
import { ROUTES } from "@constants";
import { useUserStore } from "@store/UserStore";
import { usePrevious } from "@hooks";
import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { useAuthenticationState } from "@hooks/useAuthenticationState";
import { ShadowDivider } from "@components/ShadowDivider";
import { createCss } from "./styles";

export const CaptchaInner: React.FC<{
  sitekey: string;
  onVerify: (token: string) => void;
}> = ({ sitekey, onVerify }) => {
  const css = createCss();
  const { trackEvent } = useTracking();
  const captchaRef = useRef<HCaptcha>(null);

  return (
    <Dialog open title=" ">
      <div css={css.captchaRoot}>
        <Typography paragraph align="center">
          To continue browsing, select checkbox
        </Typography>

        <div css={css.captchaWrapper}>
          <HCaptcha
            sitekey={sitekey}
            onVerify={onVerify}
            ref={captchaRef}
            close-callback={() => {
              trackEvent({
                action: "Close captcha",
              });
            }}
          />
        </div>

        <ShadowDivider />

        <Typography paragraph align="center">
          Why do I have to complete a CAPTCHA?
        </Typography>
        <Typography>
          Completing CAPTCHA gives you access to the website and proves that you
          are a human user and not a spammer bot.
        </Typography>
      </div>
    </Dialog>
  );
};

const CaptchaController: React.FC = () => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const [sitekey, setSiteKey] = useState("");
  const [{ user }] = useUserStore();
  const prevUser = usePrevious(user);
  const authOpened = useAuthenticationState();
  const errorPromises = useRef<
    {
      config: AxiosRequestConfig;
      code: string;
      resolve: (value: unknown) => void;
      reject: () => void;
    }[]
  >([]);

  useEffect(() => {
    if (user && !prevUser) {
      if (errorPromises.current.length) {
        trackEvent({
          action: "User authorized",
          userId: user.id,
        });

        errorPromises.current.forEach((p, i) => {
          (p.config as any)._retry = true;
          axios(p.config).then(p.resolve).catch(p.reject);
        });
        errorPromises.current = [];
      }
    }
  }, [user]);

  useEffect(() => {
    if (!authOpened) {
      trackEvent({
        action: "Close authorization warning",
      });

      const lastPromise = errorPromises.current.slice(-1)[0];

      if (!user && lastPromise?.code === "auth_required_warning") {
        errorPromises.current.forEach((p) => {
          (p.config as any)._retry = true;

          axios(p.config).then(p.resolve).catch(p.reject);
        });

        errorPromises.current = [];
      }

      if (!user && lastPromise?.code === "auth_required") {
        errorPromises.current.forEach((p) => {
          p.reject();
        });
        errorPromises.current = [];
      }
    }
  }, [authOpened]);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const captchaToken = localStorage.getItem("CAPTCHA_TOKEN");

        if (captchaToken) {
          config.headers["X-HCAPTCHA-TOKEN"] = captchaToken;
          localStorage.removeItem("CAPTCHA_TOKEN");
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const errors = error?.response?.data?.errors;

        if (errors?.[0]?.code === "hcaptcha_required") {
          setSiteKey(errors[0].meta.sitekey);

          trackEvent({
            action: "Protection shown",
            type: errors[0]?.code,
          });

          return new Promise((resolve, reject) => {
            errorPromises.current.push({
              config: error.config,
              code: "hcaptcha_required",
              reject,
              resolve,
            });
          });
        }
        if (
          errors?.[0]?.code === "auth_required" ||
          errors?.[0]?.code === "auth_required_warning"
        ) {
          trackEvent({
            action: "Protection shown",
            type: errors[0]?.code,
          });

          history.push({
            pathname: ROUTES.LOGIN_FORM,
            search: `?signUpVariant=${errors[0]?.code}`,
          });

          return new Promise((resolve, reject) => {
            errorPromises.current.push({
              config: error.config,
              code: errors[0]?.code,
              reject,
              resolve,
            });
          });
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  if (!sitekey) {
    return null;
  }

  return (
    <CaptchaInner
      sitekey={sitekey}
      onVerify={async (token) => {
        setSiteKey("");
        localStorage.setItem("CAPTCHA_TOKEN", token);

        trackEvent({
          action: "Captcha verified",
        });

        if (!errorPromises.current.length) {
          return;
        }

        const first = errorPromises.current[0];
        const rest = errorPromises.current.slice(1);

        (first.config as any)._retry = true;
        await axios(first.config).then(first.resolve).catch(first.reject);

        rest.forEach((p) => {
          (p.config as any)._retry = true;
          axios(p.config).then(p.resolve).catch(p.reject);
        });

        errorPromises.current = [];
      }}
    />
  );
};

export const Captcha = track({ page: "Data security" })(CaptchaController);
