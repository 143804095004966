import isNumber from "lodash/isNumber";
import { Models } from "@services/api";

const DAYS_IN_YEAR = 365;
const WEEKS_IN_YEAR = 52;
const MONTHS_IN_YEAR = 12;

function weeksToDays(val: number) {
  return Math.round((val / WEEKS_IN_YEAR) * DAYS_IN_YEAR);
}

export function daysToWeeks(val: number): number {
  return Math.round((val / DAYS_IN_YEAR) * WEEKS_IN_YEAR);
}

function yearsToDays(val: number) {
  return Math.round(val * DAYS_IN_YEAR);
}

export function daysToYears(val: number): number {
  return Math.round(val / DAYS_IN_YEAR);
}

function monthsToDays(val: number) {
  return Math.round((val / MONTHS_IN_YEAR) * DAYS_IN_YEAR);
}

export function daysToMonths(val: number): number {
  return Math.round((val / DAYS_IN_YEAR) * MONTHS_IN_YEAR);
}

export function normalizeAge(val: number, unit: Models.AgeUnit): number | null {
  if (!isFinite(val) || !unit || val < 0) {
    return null;
  }

  switch (unit) {
    case Models.AgeUnit.Weeks:
      return weeksToDays(val);

    case Models.AgeUnit.Months:
      return monthsToDays(val);

    case Models.AgeUnit.Years:
      return yearsToDays(val);

    default:
      return null;
  }
}

export function denormalizeAge(
  val: number | null | undefined,
  unit: Models.AgeUnit | null | undefined,
): number | null {
  if (!isNumber(val) || !unit || val < 0) {
    return null;
  }

  switch (unit) {
    case Models.AgeUnit.Weeks:
      return daysToWeeks(val);

    case Models.AgeUnit.Months:
      return daysToMonths(val);

    case Models.AgeUnit.Years:
      return daysToYears(val);

    default:
      return null;
  }
}
