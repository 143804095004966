import React from "react";

type Notification = {
  type: "error" | "success";
  message: string;
};

export type NotificationStore = {
  notification: Notification | null;
};

const defaultState: NotificationStore = {
  notification: null,
};

interface NotificationStoreActions {
  setNotification: (notification: Notification) => void;
  removeNotification: () => void;
  setUnknownErrorNotification: () => void;
}

const NotificationStoreContext = React.createContext<
  [NotificationStore, NotificationStoreActions] | null
>(null);

export const NotificationStoreProvider: React.FC<{
  initialState?: Partial<NotificationStore>;
}> = ({ children, initialState = {} }) => {
  const [state, setState] = React.useState<NotificationStore>(() => {
    return {
      ...defaultState,
      ...initialState,
    };
  });

  const setNotification = (notification: Notification) => {
    setState((prev) => ({
      ...prev,
      notification,
    }));
  };

  const setUnknownErrorNotification = () => {
    setState((prev) => ({
      ...prev,
      notification: {
        message: "Something went wrong",
        type: "error",
      },
    }));
  };

  const removeNotification = () => {
    setState((prev) => ({
      ...prev,
      notification: null,
    }));
  };

  return (
    <NotificationStoreContext.Provider
      value={[
        state,
        {
          removeNotification,
          setNotification,
          setUnknownErrorNotification,
        },
      ]}
    >
      {children}
    </NotificationStoreContext.Provider>
  );
};

export const useNotificationStore = (): [
  NotificationStore,
  NotificationStoreActions,
] => {
  const store = React.useContext(NotificationStoreContext);

  if (!store) {
    throw new Error(
      "useNotificationStore must be used within a NotificationStoreContextProvider",
    );
  }

  return store;
};
