import { createUseStyles } from "react-jss";
import { theme } from "@theme";

export default createUseStyles({
  "@global": {
    body: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSmoothing: "antialiased",
      fontWeight: 400,
      margin: 0,
      padding: 0,
    },

    "*": {
      boxSizing: "border-box",
    },

    "ol, ul": {
      padding: 0,
    },

    "a, .g-link": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      outline: "none",
      textDecoration: "none",
    },

    ".g-snackbar": {
      "& div[role='alert']": {
        flexWrap: "nowrap",
      },
    },

    ".g-underline": {
      textDecoration: "underline",
    },

    ".ql-container": {
      fontSize: "inherit !important",
    },

    ".fslightbox-slide-number-container": {
      fontSize: "20px !important",
    },

    "@media screen and (max-device-width: 480px)": {
      body: {
        WebkitTextSizeAdjust: "none",
      },
    },
  },
});
