import React, { useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Location } from "history";

import { globalConfig } from "@constants";
import { useUserStore } from "@store/UserStore";

/* eslint-disable */
function gtag() {
  window.dataLayer.push(arguments);
}
/* eslint-enable */

interface Config {
  user_id: number | string | undefined;
  page_location: string;
  send_page_view: boolean;
  page_path: string;
}

const GoogleAnalyticsTracker: React.FC = () => {
  const history = useHistory();
  const [{ user }] = useUserStore();
  const gaConfig = useRef<Partial<Config>>({});

  const set = useCallback((params: Partial<Config>) => {
    gaConfig.current = {
      ...gaConfig.current,
      ...params,
    };

    window.gtag("config", globalConfig.GA_ID, gaConfig.current);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${globalConfig.GA_ID}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = gtag;

    window.gtag("js", new Date());
    window.gtag("config", globalConfig.GA_ID);

    setIdentify();

    history.listen((location: Location) => {
      const { pathname, search, hash } = location;
      const url = pathname + search + hash;

      set({
        page_path: url,
        send_page_view: true,
      });
    });
  }, []);

  useEffect(() => {
    setIdentify();
  }, [user]);

  function setIdentify() {
    if (user) {
      set({
        user_id: user.id,
      });
    } else {
      set({
        user_id: undefined,
      });
    }
  }

  return null;
};

const GoogleAnalyticsTrackerWrapper: React.FC = () => {
  if (!globalConfig.GA_ID) {
    return null;
  }

  return <GoogleAnalyticsTracker />;
};

export default GoogleAnalyticsTrackerWrapper;
