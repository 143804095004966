import { globalConfig } from "@constants";

import {
  FacilitiesApi,
  Configuration,
  UsersApi,
  FacilityCardsApi,
  GeoApi,
  FacilityReviewsApi,
  BookingApi,
  ChildrenApi,
  PaymentsApi,
  SupportApi,
  CorporationsApi,
  Corporation,
  FacilityCard as FacilityCardDto,
  FacilityCardsApiFacilityCardsIndexRequest,
  FacilitiesApiFacilitiesIndexRequest,
  FacilitiesApiFacilityContactRequestsIndexRequest,
  ChildrenApiChildrenByIdDeleteRequest,
  BookingApiBookingRequestsIndexRequest,
  BookingApiBookingRequestsByIdCancelRequest,
  FacilitiesApiFacilitiesByIdGetRequest,
  FacilityCardsApiFacilityCardsByIdRequest,
  UsersApiAccountsGetByIdRequest,
  BookingApiBookingConfigsByIdGetRequest,
} from "../../api/typescript";
import { FacilityCard } from "@models/FacilityCard";
import { BookingRequest } from "@models/BookingRequest";

const config = new Configuration({
  basePath: globalConfig.API_URL,
});

class ApiBase {
  private corporationsApi = new CorporationsApi(config);
  private facilityCardsApi = new FacilityCardsApi(config);
  private facilitiesApi = new FacilitiesApi(config);
  private childrenApi = new ChildrenApi(config);
  private bookingApi = new BookingApi(config);
  private usersApi = new UsersApi(config);

  public corporations = {
    getList: async (): Promise<Corporation[]> => {
      const result = await this.corporationsApi.corporationsIndex();

      return result.data.data;
    },
  };

  public facilityCards = {
    getList: async (
      params?: FacilityCardsApiFacilityCardsIndexRequest,
    ): Promise<FacilityCard[]> => {
      const result = await this.facilityCardsApi.facilityCardsIndex(params);

      return result.data.data.map(FacilityCard.fromDto);
    },
    findOne: async (
      params: FacilityCardsApiFacilityCardsByIdRequest,
    ): Promise<FacilityCard> => {
      const response = await this.facilityCardsApi.facilityCardsById(params);

      return FacilityCard.fromDto(response.data);
    },
  };

  public facilities = {
    // findOne: async (
    //   params?: FacilitiesApiFacilitiesByIdGetRequest,
    // ): Promise<Facility>
  };

  public contactRequests = {
    getList: async (
      params?: FacilitiesApiFacilityContactRequestsIndexRequest,
    ) => {
      const result = await this.facilitiesApi.facilityContactRequestsIndex(
        params,
      );

      return result.data.data;
    },
  };

  public children = {
    getList: async () => {
      const result = await this.childrenApi.childrenIndex();

      return result.data.data;
    },
    deleteOne: async (params: ChildrenApiChildrenByIdDeleteRequest) => {
      await this.childrenApi.childrenByIdDelete(params);
    },
  };

  public booking = {
    getList: async (params?: BookingApiBookingRequestsIndexRequest) => {
      const result = await this.bookingApi.bookingRequestsIndex(params);

      return result.data.data.map((r) => BookingRequest.fromDto(r));
    },
    cancel: async (params: BookingApiBookingRequestsByIdCancelRequest) => {
      const response = await this.bookingApi.bookingRequestsByIdCancel(params);

      return BookingRequest.fromDto(response.data);
    },
    configFindOne: async (params: BookingApiBookingConfigsByIdGetRequest) => {
      const response = await this.bookingApi.bookingConfigsByIdGet(params);

      return response.data;
    },
  };

  public users = {
    accountFindOne: async (params: UsersApiAccountsGetByIdRequest) => {
      const response = await this.usersApi.accountsGetById(params);

      return response.data;
    },
  };
}

export const FACILITY_API = new FacilitiesApi(config);
export const FACILITY_CARD_API = new FacilityCardsApi(config);
export const USER_API = new UsersApi(config);
export const GEO_API = new GeoApi(config);
export const FACILITY_REVIEWS_API = new FacilityReviewsApi(config);
export const CHILDREN_API = new ChildrenApi(config);
export const BOOKING_API = new BookingApi(config);
export const PAYMENTS_API = new PaymentsApi(config);
export const SUPPORT_API = new SupportApi(config);
export const API = new ApiBase();

export * as Models from "../../api/typescript/api";
