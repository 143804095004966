import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  captchaRoot: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      maxWidth: 360,
    },
  }),

  captchaWrapper: {
    display: "flex",
    justifyContent: "center",
  },
});
