/* global ProtoExtends */
import React from "react";
import MaterialIconButton, {
  IconButtonProps,
} from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import omit from "lodash/omit";

import { ITheme } from "@theme";

const useStyles = (theme: ITheme) => ({
  root: (props: IProps) => {
    let styles = {};

    if (props.color === "text") {
      styles = {
        ...styles,
        color: theme.palette.text.primary,
      };
    }

    return styles;
  },
});

type IProps = ProtoExtends<
  IconButtonProps,
  {
    color: IconButtonProps["color"] | "text";
  }
>;

const Button: React.FC<IProps> = (props) => {
  const omitted = omit(
    props,
    ["fab", props.color === "text" ? "color" : undefined].filter(
      (p) => p !== undefined,
    ) as string[],
  ) as IconButtonProps;

  return <MaterialIconButton {...omitted} />;
};

export default withStyles(useStyles as any)(Button);
