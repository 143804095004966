import { useRef } from "react";

function useWillMount(action: () => void): void {
  const mountedRef = useRef(false);

  if (!mountedRef.current) {
    action();
  }

  mountedRef.current = true;
}

export default useWillMount;
