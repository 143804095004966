import { Interpolation } from "@emotion/react";
import { fade, darken } from "@material-ui/core/styles";

import { ITheme } from "@theme";
import { Classes, ButtonProps } from "./types";

export const useCss = (
  props: Partial<ButtonProps>,
): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    borderRadius: props.fab ? 17 : 4,
    borderWidth: "2px !important",
    fontWeight: 600,
    position: "relative",
    textTransform: props.textTransform,

    "&.bolded": {
      fontWeight: 700,
    },

    "&.contained": (props.disabled || props.loading) && {
      backgroundColor: "grey !important",
      color: "white !important",
    },

    "&.success.contained": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.text.primary,

      "&:hover": {
        backgroundColor: theme.palette.success.dark,

        "@media (hover: none)": {
          backgroundColor: theme.palette.success.main,
        },
      },
    },

    "&.success.outlined": {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,

      "&:hover": {
        backgroundColor: fade(
          theme.palette.success.main,
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    "&.error.contained": {
      backgroundColor: theme.palette.error.main,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.error.dark,

        "@media (hover: none)": {
          backgroundColor: theme.palette.error.main,
        },
      },
    },

    "&.warning.contained": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.primary,

      "&:hover": {
        backgroundColor: theme.palette.warning.dark,

        "@media (hover: none)": {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },

    "&.pink.contained": {
      backgroundColor: theme.palette.pink.main,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.pink.dark,

        "@media (hover: none)": {
          backgroundColor: theme.palette.pink.main,
        },
      },
    },

    "&.pink.outlined": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,

      "&:hover": {
        backgroundColor: fade(
          theme.palette.pink.main,
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    "&.error.text": {
      color: theme.palette.error.main,
    },

    "& svg": {
      marginRight: 7,
    },
  }),

  content: {
    alignItems: "center",
    display: "flex",

    "&.hidden": {
      visibility: "hidden",
    },
  },

  loader: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,

    "& .spinner": {
      color: "inherit",

      "& svg": {
        marginRight: 0,
      },
    },
  },

  blackOutlined: (theme) => ({
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,

    "&:hover": {
      backgroundColor: fade(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity,
      ),
    },
  }),

  blackContained: (theme) => ({
    backgroundColor: theme.palette.text.primary,
    color: "white",

    "&:hover": {
      backgroundColor: darken(theme.palette.text.primary, 0.3),

      "@media (hover: none)": {
        backgroundColor: theme.palette.text.primary,
      },
    },
  }),

  yellowOutlined: (theme) => ({
    borderColor: theme.palette.yellow.main,
    color: theme.palette.yellow.main,

    "&:hover": {
      backgroundColor: fade(
        theme.palette.yellow.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  }),

  yellowContained: (theme) => ({
    backgroundColor: theme.palette.yellow.main,
    color: theme.palette.text.primary,

    "&:hover": {
      backgroundColor: darken(theme.palette.yellow.main, 0.3),

      "@media (hover: none)": {
        backgroundColor: theme.palette.yellow.main,
      },
    },
  }),

  whiteOutlined: (theme) => ({
    borderColor: "white",
    color: "white",

    "&:hover": {
      backgroundColor: fade("#ffffff", theme.palette.action.hoverOpacity),
    },
  }),

  whiteContained: (theme) => ({
    backgroundColor: "white",
    color: theme.palette.text.primary,

    "&:hover": {
      backgroundColor: darken("#ffffff", 0.3),

      "@media (hover: none)": {
        backgroundColor: theme.palette.yellow.main,
      },
    },
  }),

  primaryOutlined: (theme) => ({
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: fade(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  }),

  primaryContained: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: "white",

    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.3),

      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),

  secondaryOutlined: (theme) => ({
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,

    "&:hover": {
      backgroundColor: fade(
        theme.palette.secondary.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  }),

  secondaryContained: (theme) => ({
    backgroundColor: theme.palette.secondary.main,
    color: "white",

    "&:hover": {
      backgroundColor: darken(theme.palette.secondary.main, 0.3),

      "@media (hover: none)": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
});
