/* istanbul ignore file */

import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { Router } from "react-router-dom";
import { loadableReady } from "@loadable/component";
import MockDate from "mockdate";
import { JssProvider, ThemeProvider } from "react-jss";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { theme } from "@theme";
import { history } from "@store";
import { RootStoreProvider } from "@store/RootStore";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import SentryFullStory from "@sentry/fullstory";
import * as FullStory from "@fullstory/browser";

import App from "./App";
import { globalConfig } from "@constants";
import { createGenerateId } from "@helpers/createGenerateId";
import UserRequestInterceptor from "@services/userRequestInterceptor";

if (typeof globalConfig.CUSTOM_DATE === "string") {
  MockDate.set(globalConfig.CUSTOM_DATE);
}

if (globalConfig.FULLSTORY_ORG_ID) {
  FullStory.init({ orgId: globalConfig.FULLSTORY_ORG_ID });
}

Sentry.init({
  dsn: globalConfig.SENTRY_KEY,
  environment: globalConfig.ENVIRONMENT,
  integrations: [
    globalConfig.FULLSTORY_ORG_ID &&
      new SentryFullStory(globalConfig.SENTRY_ORGANIZATION),
    new Integrations.BrowserTracing(),
  ].filter(Boolean),
  release: globalConfig.RELEASE,
});

UserRequestInterceptor.create();

const preloadedState = window.__INITIAL_STATE__;
const dehydratedState = window.__REACT_QUERY_STATE__;
delete window.__INITIAL_STATE__;
delete window.__REACT_QUERY_STATE__;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity },
  },
});

void loadableReady(() => {
  const generateId = createGenerateId();

  ReactDOM.hydrate(
    <Router history={history}>
      <JssProvider generateId={generateId}>
        <ThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={dehydratedState}>
                  <RootStoreProvider initialState={preloadedState}>
                    <App />
                  </RootStoreProvider>
                </Hydrate>
              </QueryClientProvider>
            </MuiThemeProvider>
          </EmotionThemeProvider>
        </ThemeProvider>
      </JssProvider>
    </Router>,
    document.getElementById("root"),
  );
});
