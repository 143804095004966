export const initEnvironment = (): void => {
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  String.prototype.uncapitalize = function () {
    return this.charAt(0).toLowerCase() + this.slice(1);
  };

  String.prototype.ellipsis = function (n: number) {
    if (this.length > n) {
      return this.slice(0, n - 1) + "..";
    }

    return this.toString();
  };
};

interface LoadableRetryOptions {
  retries?: number;
  interval?: number;
  exponentialBackoff?: boolean;
}

export function retry(
  fn: () => Promise<any>,
  {
    retries = 3,
    interval = 500,
    exponentialBackoff = true,
  }: LoadableRetryOptions = {},
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error);
            return;
          }

          retry(fn, {
            interval: exponentialBackoff ? interval * 2 : interval,
            retries: retries - 1,
          }).then(resolve, reject);
        }, interval);
      });
  });
}
