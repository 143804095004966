import { createUseStyles } from "react-jss";

export default createUseStyles(
  {
    progressOverlay: {
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, .8)",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      left: 0,
      position: "fixed",
      right: 0,
      top: 0,
      zIndex: 99999,
    },
  },
  { name: "ProgressSpinner" },
);
