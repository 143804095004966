import { Models } from "@services/api";

interface Location {
  lat: number;
  lon: number;
}

export class FacilityBase {
  public id: string;
  public accountId: number | null = null;
  public name: string;
  public type: string;
  public address: {
    street: string;
    city: string;
    state: string;
    zip: string;
    timezone: string;
    country: string;
    location: Location;
  };

  constructor(
    id: string,
    name: string,
    type: string,
    addressStreet: string,
    addressCity: string,
    addressState: string,
    addressCountry: string,
    addressZip: string,
    timezone: string,
    location: Location,
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.address = {
      street: addressStreet,
      city: addressCity,
      state: addressState,
      zip: addressZip,
      country: addressCountry,
      timezone,
      location,
    };
  }

  public getFullAddress = (): string => {
    return `${this.address.street}, ${this.address.city}, ${this.address.state}, ${this.address.zip}`;
  };

  public getTimezone = (): string => {
    return this.address.timezone;
  };

  static fromDto(dto: Models.Facility | Models.FacilityCard): FacilityBase {
    const facility = new FacilityBase(
      dto.id,
      dto.name,
      dto.facilityType,
      dto.address.street,
      dto.address.city,
      dto.address.state,
      dto.address.country,
      dto.address.zip,
      dto.address.timezone,
      dto.address.location,
    );

    facility.accountId = dto.accountId || null;

    return facility;
  }
}
