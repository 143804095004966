import React, { useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import isUndefined from "lodash/isUndefined";
import { useTracking } from "react-tracking";

import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialButton, {
  ButtonProps as MaterialButtonProps,
} from "@material-ui/core/Button";

import { ButtonIcon } from "../ButtonIcon";
import { ButtonProps, Classes } from "./types";
import { useCss } from "./styles";

const getStyleClass = (
  color: ButtonProps["color"],
  variant: ButtonProps["variant"],
): Classes | undefined => {
  if (color === "black" && variant === "contained") {
    return "blackContained";
  }
  if (color === "black" && variant === "outlined") {
    return "blackOutlined";
  }
  if (color === "yellow" && variant === "contained") {
    return "yellowContained";
  }
  if (color === "yellow" && variant === "outlined") {
    return "yellowOutlined";
  }
  if (color === "white" && variant === "contained") {
    return "whiteContained";
  }
  if (color === "white" && variant === "outlined") {
    return "whiteOutlined";
  }
  if (color === "primary" && variant === "outlined") {
    return "primaryOutlined";
  }
  if (color === "primary" && variant === "contained") {
    return "primaryContained";
  }
  if (color === "secondary" && variant === "outlined") {
    return "secondaryOutlined";
  }
  if (color === "secondary" && variant === "contained") {
    return "secondaryContained";
  }
  return undefined;
};

const Button: React.FC<ButtonProps> & {
  Icon: typeof ButtonIcon;
} = ({
  loading,
  disabled,
  children,
  textTransform = "none",
  bolded = false,
  color = "primary",
  fab = true,
  variant = "text",
  name,
  link,
  onClick,
  ...props
}) => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const { search } = useLocation();
  const css = useMemo(() => {
    return useCss({
      bolded,
      color,
      disabled,
      fab,
      loading,
      textTransform,
      variant,
    });
  }, [bolded, color, disabled, fab, textTransform, variant, loading]);
  const styleClass = useMemo(
    () => getStyleClass(color, variant),
    [variant, color],
  );
  const isMaterialColor = color === "default";

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.persist();

      if (name) {
        trackEvent({
          action: "Button Click",
          name,
        });
      }

      if (!isUndefined(link)) {
        if (typeof link === "string") {
          history.push({
            pathname: link,
            search,
          });
        } else {
          history.push(link);
        }
      }
      onClick?.(event);
    },
    [onClick, link, search, history],
  );

  return (
    <MaterialButton
      data-test={name ? `${name}-button` : undefined}
      {...props}
      onClick={handleClick}
      color={
        isMaterialColor ? (color as MaterialButtonProps["color"]) : undefined
      }
      variant={variant}
      disabled={disabled || loading}
      classes={{
        root: clsx(props.classes?.root, {
          [color]: true,
          [variant]: true,
          bolded,
          fab,
        }),
      }}
      css={[css.root, styleClass && css[styleClass]]}
    >
      {loading && (
        <div css={css.loader}>
          <CircularProgress className="spinner" size={24} />
        </div>
      )}
      <div
        css={css.content}
        className={clsx({
          hidden: loading,
        })}
      >
        {children}
      </div>
    </MaterialButton>
  );
};

Button.Icon = ButtonIcon;

export default Button;
