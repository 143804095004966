import { createBrowserHistory, History } from "history";

import isBrowser from "@helpers/is-browser";

let history: History;

if (isBrowser()) {
  history = createBrowserHistory();
}

export { history };
