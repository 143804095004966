import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { globalConfig } from "@constants";
import { useUserStore } from "@store/UserStore";

const reactPlugin = new ReactPlugin();

const ApplicationInsightsTracker: React.FC = () => {
  const history = useHistory();

  if (!globalConfig.APPLICATION_INSIGHTS_KEY) {
    return null;
  }

  const [{ user }] = useUserStore();
  const appInsightsRef = useRef<ApplicationInsights | null>(null);

  useEffect(() => {
    const appInsights = new ApplicationInsights({
      config: {
        correlationHeaderExcludedDomains: [
          "mapbox.com",
          "cloudinary.com",
          "doubleclick.net",
          "*.akamaihd.net",
          "*.litix.io",
          "*.wistia.com",
          "*.yandex.ru",
          "*.fullstory.com",
        ],
        disableCorrelationHeaders: false,
        enableCorsCorrelation: true,
        extensionConfig: {
          [reactPlugin.identifier]: {
            history,
          },
        },
        extensions: [reactPlugin],
        instrumentationKey: globalConfig.APPLICATION_INSIGHTS_KEY,
      },
    });

    appInsightsRef.current = appInsights;
    appInsights.loadAppInsights();

    setIdentify();

    appInsights.addTelemetryInitializer((envelope) => {
      if (!envelope.tags) {
        envelope.tags = [];
      }

      envelope.tags.push({
        "ai.cloud.role":
          globalConfig.APPLICATION_INSIGHTS_CLIENT_CLOUD_ROLE_NAME,
      });
      envelope.tags.push({ "ai.application.ver": globalConfig.RELEASE });
    });
  }, []);

  useEffect(() => {
    setIdentify();
  }, [user]);

  function setIdentify() {
    if (user) {
      appInsightsRef.current?.setAuthenticatedUserContext(
        user.id.toString(),
        user.account.publicId,
      );
    }
  }

  return null;
};

export default ApplicationInsightsTracker;
