import React, { useState, useEffect } from "react";

import { TABLET_BREAKPOINT } from "@theme";
import isBrowser from "@helpers/is-browser";
import { useRootStore } from "@store/RootStore";

export interface Viewport {
  width: number;
  isMobile: boolean;
}

export const getViewport = (width: number): Viewport => {
  return {
    isMobile: width < TABLET_BREAKPOINT,
    width,
  };
};

const defaultViewport = getViewport(isBrowser() ? window.innerWidth : 0);

const ViewportContext = React.createContext<Viewport>(defaultViewport);

export const ViewportProvider: React.FC = ({ children }) => {
  const root = useRootStore();
  const [viewport, setViewport] = React.useState<Viewport>(() => {
    return {
      ...defaultViewport,
      ...(root?.viewport || {}),
    };
  });

  useEffect(() => {
    const handleResize = () => setViewport(getViewport(window.innerWidth));

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = (): Viewport => {
  const viewport = React.useContext(ViewportContext);

  if (!viewport) {
    throw new Error("useViewport must be used within a ViewportProvider");
  }

  return viewport;
};
