import numeral from "numeral";

export const format_number = (
  value: number,
  options?: Partial<{
    roundTo: number;
    money: boolean;
    format: "$0,0.00" | "$0,0";
  }>,
): string => {
  let v = value;
  let f = options?.format || "0,0";

  if (options?.roundTo) {
    v = Math.round(v / options.roundTo) * options.roundTo;
  }

  if (options?.money) {
    f = "$0,0";
  }

  return numeral(v).format(f);
};

export const format_percent = (
  value: number,
  options?: Partial<{
    round: boolean;
  }>,
): string => {
  let v = value;

  if (options?.round) {
    v = Math.round(value * 100) / 100;
  }

  return numeral(v).format("0%");
};
