import orderBy from "lodash/orderBy";
import isNumber from "lodash/isNumber";
import isNil from "lodash/isNil";

import { Models } from "@services/api";
import { convertSecondsToTime } from "@helpers/convert-seconds-to-time";
import { daysMap } from "@constants/days-of-week";
import formatAgeRange from "@helpers/format-age-range";
import { format_number } from "@helpers/format_number";

export const getFacilityFullAddress = (
  facility: Models.Facility | Models.FacilityCard | null,
): string | null => {
  if (!facility) {
    return null;
  }
  return `${facility.address.street}, ${facility.address.city}, ${facility.address.state}, ${facility.address.zip}`;
};

export const formatYrsInBusiness = (facility: Models.Facility): string => {
  if (!facility.operatingInfo.foundedAt) {
    return "";
  }

  const currentYear = new Date().getFullYear();
  const diff = currentYear - facility.operatingInfo.foundedAt;

  if (diff >= 10) {
    return "10+ yrs";
  } else if (diff < 1) {
    return "less than 1 year";
  } else {
    return `${diff} yrs`;
  }
};

export const getSchoolAgeRange = (facility: Models.Facility): string | null => {
  if (isNil(facility.operatingInfo.ageFrom)) {
    return null;
  }

  return formatAgeRange(
    facility.operatingInfo.ageFrom,
    facility.operatingInfo.ageFromUnit,
    facility.operatingInfo.ageTo,
    facility.operatingInfo.ageToUnit,
  );
};

export const getAvgClassSize = (facility: Models.Facility): number | null => {
  if (!facility.operatingInfo.numberOfClassrooms) {
    return null;
  }

  if (!isNil(facility.operatingInfo.currentEnrollment)) {
    return Math.floor(
      facility.operatingInfo.currentEnrollment /
        facility.operatingInfo.numberOfClassrooms,
    );
  }

  if (!isNil(facility.operatingInfo.enrollmentCapacity)) {
    return Math.floor(
      facility.operatingInfo.enrollmentCapacity /
        facility.operatingInfo.numberOfClassrooms,
    );
  }

  return null;
};

export const formatDiscountValue = (value: Models.DiscountID[]): string => {
  if (!value.length) {
    return "---";
  }

  if (value.length === 1) {
    return `${value[0]}%`;
  }

  const parsed = value.map((v) => parseInt(v, 10));
  const max = Math.max(...parsed);
  const min = Math.min(...parsed);

  return `${min}% - ${max}%`;
};

export const getChildFullName = (child: Models.Child | null): string | null => {
  if (!child) {
    return null;
  }
  return child.firstName + " " + child.lastName;
};

export const getUserFullName = (user: Models.User): string => {
  return user.firstName + " " + user.lastName;
};

export const getShiftName = (startTime: number | null): string => {
  switch (startTime) {
    case 32400:
      return "Early bird";
    case 43200:
      return "Brunch";
    case 54000:
      return "Siesta";
    case 64800:
      return "Date night";
    default:
      return "";
  }
};

export const isWeekend = (day: Models.WeekDay): boolean => {
  return day === Models.WeekDay.Saturday || day === Models.WeekDay.Sunday;
};

export const isWeekday = (day: Models.WeekDay): boolean => {
  return (
    day === Models.WeekDay.Monday ||
    day === Models.WeekDay.Tuesday ||
    day === Models.WeekDay.Wednesday ||
    day === Models.WeekDay.Thursday ||
    day === Models.WeekDay.Friday
  );
};

export const isPublicSchool = (
  facility: Models.Facility | Models.FacilityCard,
): boolean => {
  return facility.facilityType === Models.FacilityTypeID.PublicSchool;
};

export const isPrivateSchool = (
  facility: Models.Facility | Models.FacilityCard,
): boolean => {
  return facility.facilityType === Models.FacilityTypeID.PrivateSchool;
};

export const isChildcareCenter = (
  facility: Models.Facility | Models.FacilityCard,
): boolean => {
  return facility.facilityType === Models.FacilityTypeID.DaycareCenter;
};

export const isPreSchool = (
  facility: Models.Facility | Models.FacilityCard,
): boolean => {
  return facility.facilityType === Models.FacilityTypeID.PreSchool;
};

export const isOshcSchool = (
  facility: Models.Facility | Models.FacilityCard,
): boolean => {
  return facility.facilityType === Models.FacilityTypeID.Oshc;
};

export const getFacilityTuitionString = (
  facility: Models.Facility,
): string | null => {
  if (
    !isNumber(facility.tuitionInfo.costFrom) ||
    !isNumber(facility.tuitionInfo.costTo)
  ) {
    return null;
  }

  if (facility.tuitionInfo.costFrom === facility.tuitionInfo.costTo) {
    return format_number(facility.tuitionInfo.costFrom / 100, {
      format: "$0,0",
    });
  }

  return (
    `${format_number(facility.tuitionInfo.costFrom / 100, {
      format: "$0,0",
    })}` +
    ` - ${format_number(facility.tuitionInfo.costTo / 100, { format: "$0,0" })}`
  );
};

export const getFormattedSchedule = (
  schedule: Models.FacilityScheduleDay[] | null | undefined,
): string | null => {
  if (!schedule) {
    return null;
  }

  const ordered = orderBy(schedule, (op) => daysMap[op.day].order);
  const results: {
    days: string[];
    start: number;
    end: number;
  }[] = [];

  ordered.forEach((op) => {
    const prevOpHour = results[results.length - 1];

    if (
      prevOpHour &&
      prevOpHour.start === op.start &&
      prevOpHour.end === op.end &&
      daysMap[prevOpHour.days[prevOpHour.days.length - 1]].order ===
        daysMap[op.day].order - 1
    ) {
      prevOpHour.days.push(op.day);
    } else {
      results.push({
        days: [op.day],
        end: op.end,
        start: op.start,
      });
    }
  });

  if (results.length === 0) {
    return null;
  }

  return `${convertSecondsToTime(results[0].start)} - ${convertSecondsToTime(
    results[0].end,
  )}`;
};

export const orderSchoolMedia = (
  facility: Models.Facility,
  type: "photo" | "video" | "testimonials" = "photo",
): {
  id: Models.MediaCategory;
  items: Models.Media[];
}[] => {
  const order =
    type === "photo"
      ? [
          Models.MediaCategory.Profile,
          Models.MediaCategory.StreetView,
          Models.MediaCategory.Playground,
          Models.MediaCategory.Staff,
          Models.MediaCategory.Meals,
          Models.MediaCategory.Classrooms,
          Models.MediaCategory.InfantClassroom,
          Models.MediaCategory.ToddlerClassroom,
          Models.MediaCategory.ClassroomPreK2,
          Models.MediaCategory.ClassroomPreK3,
          Models.MediaCategory.ClassroomPreK4,
          Models.MediaCategory.ClassroomTk,
          Models.MediaCategory.ClassroomK,
          Models.MediaCategory.SchoolAgeProgram,
          Models.MediaCategory.SummerCamp,
          Models.MediaCategory.ServicePdo,
          Models.MediaCategory.SecurityAndSafety,
        ]
      : type === "video"
      ? [
          Models.MediaCategory.VideoAboutTheSchool,
          Models.MediaCategory.VideoServicePdo,
        ]
      : type === "testimonials"
      ? [
          Models.MediaCategory.TestimonialAboutTheSchool,
          Models.MediaCategory.TestimonialServicePdo,
        ]
      : [];
  const photos = order.map((category) => {
    return {
      id: category,
      items: orderBy(
        facility.media.filter((m) => m.category === category),
        "createdAt",
        "asc",
      ),
    };
  });

  return photos;
};

export const isFacilityOwner = (
  facility: Models.Facility,
  user: Models.User | null,
): boolean => {
  if (!facility.accountId) {
    return false;
  }

  return facility.accountId === user?.account.id;
};

export const isAdmin = (user: Models.User | null): boolean => {
  return user?.account.type === Models.AccountType.System;
};

export const isCorpBenefit = (user: Models.User | null): boolean => {
  return user?.account.type === Models.AccountType.CorpBenefit;
};

export const isParent = (user: Models.User | null): boolean => {
  return user?.account.type === Models.AccountType.Parents;
};
