import { DateTime, Duration } from "luxon";

export const getAge = (
  date: string,
): {
  age: {
    days: number;
    months: number;
    years: number;
  };
  str: string | null;
} | null => {
  const now = DateTime.local();
  const DOB = DateTime.fromISO(date);

  if (!DOB.isValid) {
    return null;
  }

  const yearNow = now.get("year");
  const monthNow = now.get("month");
  const dateNow = now.get("day");

  const yearDob = DOB.get("year");
  const monthDob = DOB.get("month");
  const dateDob = DOB.get("day");

  let years: number;
  let months: number;
  let days: number;

  years = yearNow - yearDob;

  if (monthNow >= monthDob) {
    months = monthNow - monthDob;
  } else {
    years--;
    months = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) {
    days = dateNow - dateDob;
  } else {
    months--;
    days = 31 + dateNow - dateDob;

    if (months < 0) {
      months = 11;
      years--;
    }
  }

  const dayString = days > 1 ? "days" : "day";
  const monthString = months > 1 ? "months" : "month";
  const yearString = years > 1 ? "years" : "year";
  let ageString: string | null;

  if (years < 0) {
    ageString = null;
  } else if (years === 0 && months === 0) {
    ageString = `${days} ${dayString}`;
  } else if (years === 0) {
    ageString = `${months} ${monthString}`;
  } else if (months === 0) {
    ageString = `${years} ${yearString}`;
  } else {
    ageString = `${years} ${yearString} ${months} ${monthString}`;
  }

  const millis = DateTime.fromISO(date).diffNow().milliseconds;

  if (Duration.fromMillis(millis * -1).as("days") <= 60) {
    ageString = "newborn";
  }

  return {
    age: {
      days,
      months,
      years,
    },
    str: ageString,
  };
};
