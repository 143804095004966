import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes, ButtonIconProps } from "./types";

export const useCss = (
  props: Partial<ButtonIconProps>,
): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: props.size === "small" ? 16 : 24,
    justifyContent: "center",
    marginRight: 7,
    width: props.size === "small" ? 16 : 24,

    ...(props.position === "right"
      ? {
          position: "absolute",
          right: 5,
          top: "50%",
          transform: "translateY(-50%)",
        }
      : {}),

    "& svg": {
      height: "100%",
      marginRight: "0 !important",
      width: "100%",
    },
  },
});
