import { DateTime } from "luxon";

export const convertSecondsToTime = (
  seconds: number,
  compact?: boolean,
): string => {
  const time = DateTime.utc().startOf("day").plus({
    seconds,
  });

  if (compact && time.get("minute") === 0) {
    return time.toFormat("h a").toLowerCase();
  }

  return time.toFormat("h:mma").toLowerCase();
};
