import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./styles";

const ProgressSpinner: React.FunctionComponent = () => {
  const elRef = useRef<HTMLDivElement>(document.createElement("div"));

  const classes = useStyles();

  useEffect(() => {
    const loaderRoot = document.getElementById("loader-root");

    if (loaderRoot === null) {
      return;
    }

    loaderRoot.appendChild(elRef.current);

    return () => {
      loaderRoot.removeChild(elRef.current);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={classes.progressOverlay} data-test="progress-loader">
      <CircularProgress />
    </div>,
    elRef.current,
  );
};

export default ProgressSpinner;
