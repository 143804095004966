import React from "react";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import { Models } from "@services/api";
import { ShadowDivider } from "@components/ShadowDivider";

type CloseWarningProps = {
  onClose: () => void;
  onQuit: () => void;
  quitLoading?: boolean;
  tier?: Models.SubscriptionTier;
};

export const QuitDialog: React.FC<CloseWarningProps> = ({
  onClose,
  onQuit,
  quitLoading,
  tier,
}) => {
  const css = createCss();

  return (
    <Dialog
      open
      onClose={onClose}
      title="Quit?"
      data-test="quit-claim-dialog"
      tier={tier}
    >
      <div css={css.container}>
        <Typography align="center">All edits will be lost</Typography>

        <ShadowDivider />

        <div css={css.actions}>
          <Button
            color="error"
            variant="contained"
            size="large"
            data-test="quit-button"
            onClick={onQuit}
            loading={quitLoading}
          >
            Quit
          </Button>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
