import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

type AgeUnit = {
  id: Models.AgeUnit;
  title: string;
};

export const ageUnits: AgeUnit[] = [
  {
    id: Models.AgeUnit.Weeks,
    title: "wks",
  },
  {
    id: Models.AgeUnit.Months,
    title: "mo",
  },
  {
    id: Models.AgeUnit.YearsMonths,
    title: "yrs.mo",
  },
  {
    id: Models.AgeUnit.Years,
    title: "yrs",
  },
];

export const ageUnitsMap = keyBy(ageUnits, "id");
