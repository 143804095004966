import axios from "axios";
import cookies from "browser-cookies";

import { TOKEN_KEY, globalConfig } from "@constants";

class UserRequestInterceptor {
  private interceptor: number | null = null;

  public create = () => {
    this.interceptor = axios.interceptors.request.use((config) => {
      const authToken = cookies.get(TOKEN_KEY);

      if (authToken && !config.headers.Authorization) {
        if (config.url?.includes(globalConfig.API_URL)) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
      }

      return config;
    });
  };

  public close = () => {
    if (this.interceptor) {
      axios.interceptors.request.eject(this.interceptor);
    }
  };
}

export default new UserRequestInterceptor();
