import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ROUTES } from "@constants";

const authorizationRoutes = [
  ROUTES.LOGIN_FORM,
  ROUTES.SIGNUP_FORM,
  ROUTES.FORGOT_PASSWORD_PAGE,
];

export function useAuthenticationState() {
  const location = useLocation();
  const [state, setState] = useState(() => {
    return authorizationRoutes.includes(location.pathname);
  });

  useEffect(() => {
    setState(authorizationRoutes.includes(location.pathname));
  }, [location]);

  return state;
}
