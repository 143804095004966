import React, { useRef, useCallback } from "react";
import { Location } from "history";

import { useRootStore } from "./RootStore";

export type AppStore = {
  loader: boolean;
  location: Location;
};

export type AppStoreWithRefs = AppStore & {
  realLocation: React.MutableRefObject<Location>;
};

const defaultLocation: Location = {
  hash: "",
  pathname: "/",
  search: "",
  state: "",
};

const defaultState: AppStore = {
  loader: false,
  location: defaultLocation,
};

export interface AppStoreActions {
  setLoader: (state: boolean) => void;
  setRealLocation: (location: Location) => void;
}

const AppStoreContext = React.createContext<
  [AppStoreWithRefs, AppStoreActions] | null
>(null);

export const AppStoreProvider: React.FC<{
  initialState?: Partial<AppStore>;
}> = ({ children, initialState = {} }) => {
  const root = useRootStore();
  const [state, setState] = React.useState<AppStore>(() => {
    return {
      ...defaultState,
      ...(root?.app || {}),
      ...initialState,
    };
  });
  const realLocation = useRef<Location>(state.location || defaultLocation);

  const setRealLocation = useCallback(
    (loc: Location) => {
      realLocation.current = loc;
      setState((prev) => ({
        ...prev,
        location: loc,
      }));
    },
    [setState],
  );

  const setLoader = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        loader: state,
      }));
    },
    [setState],
  );

  return (
    <AppStoreContext.Provider
      value={[
        {
          ...state,
          realLocation,
        },
        {
          setLoader,
          setRealLocation,
        },
      ]}
    >
      {children}
    </AppStoreContext.Provider>
  );
};

export const useAppStore = (): [AppStoreWithRefs, AppStoreActions] => {
  const store = React.useContext(AppStoreContext);

  if (!store) {
    throw new Error(
      "useAppStore must be used within a AppStoreContextProvider",
    );
  }

  return store;
};
