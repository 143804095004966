import React, { useCallback, useEffect } from "react";
import qs from "query-string";
import isNil from "lodash/isNil";
import { useHistory, useLocation } from "react-router-dom";

import { Models, CHILDREN_API, BOOKING_API } from "@services/api";
import { useUserStore } from "@store/UserStore";
import { usePrevious } from "@hooks";
import { must } from "@utils/must";
import { useDeepDivePanelStore } from "./useDeepDivePanelStore";

export type ReservationStore = {
  facility: Models.Facility | null;
  child: Models.Child | null;
  suggestedShifts: Models.BookingConfigSuggestedShift[];
  selectedShifts: Models.BookingConfigSuggestedShift[];
  isChildEnrolled?: boolean;
  error: ReservationError | null;
  fetching: boolean;
  childMedicalInfo: string;
  step: ReservationSteps | "error" | null;
  paymentLast4?: string | null;
  paymentSourceId: string | null;
};

type ReservationErrorCode =
  | "payment_error"
  | "booking_slot_terms_changed"
  | "booking_shifts_not_found"
  | "booking_not_eligible"
  | "booking_slot_not_available";

type ReservationError = {
  code: ReservationErrorCode | "child_error" | "unknown";
  detail?: string;
  title?: string;
  meta?: any;
};

export type ReservationSteps =
  | "additional-info"
  | "child-select"
  | "confirmation"
  | "eligibility"
  | "medical-forms"
  | "review"
  | "select-payment"
  | "shift-select";

const defaultState: ReservationStore = {
  child: null,
  childMedicalInfo: "",
  error: null,
  facility: null,
  fetching: false,
  paymentLast4: null,
  paymentSourceId: null,
  selectedShifts: [],
  step: null,
  suggestedShifts: [],
};

const STEPS_ORDER: ReservationSteps[] = [
  "eligibility",
  "child-select",
  "shift-select",
  "additional-info",
  "medical-forms",
  "select-payment",
  "review",
  "confirmation",
];

const PARENT_INFO_STEP_ORDER = 4;
const CHILD_SELECT_STEP_ORDER = 2;
const SHIFT_SELECT_STEP_ORDER = 5;
const MEDICAL_FORMS_STEP_ORDER = 3;

const STEPS_MAP: {
  [key in ReservationSteps]: number;
} = {
  "additional-info": PARENT_INFO_STEP_ORDER,
  "child-select": CHILD_SELECT_STEP_ORDER,
  confirmation: 8,
  eligibility: 1,
  "medical-forms": MEDICAL_FORMS_STEP_ORDER,
  review: 7,
  "select-payment": 6,
  "shift-select": SHIFT_SELECT_STEP_ORDER,
};

const REVERSED_STEPS_MAP = Object.keys(STEPS_MAP).reduce(
  (acc: { [key: number]: ReservationSteps }, key) => {
    acc[STEPS_MAP[key as keyof typeof STEPS_MAP]] = key as ReservationSteps;

    return acc;
  },
  {} as { [key: number]: ReservationSteps },
);

type GoNextOptions =
  | {
      type: "CHILD_SELECT";
      child: Models.Child;
    }
  | {
      type: "BOOK";
    }
  | {
      type: "PARENT_INFO";
    }
  | {
      type: "SHIFTS_SELECT";
    }
  | {
      type: "INVITATION";
      emails: string[];
    }
  | {
      type: "MEDICAL_INFO";
    }
  | {
      type: "PAYMENT_SELECT";
    }
  | {
      type: "ELIGIBILITY";
    };

interface ReservationStoreActions {
  getSuggestedShifts: (
    child?: Models.Child | null,
    setToState?: boolean,
  ) => Promise<Models.BookingConfigSuggestedShift[]>;
  setChild: (child: Models.Child) => void;
  setError: (error: ReservationError | null) => void;
  setIsChildEnrolled: (enrolled: boolean) => void;
  setChildMedicalInfo: (info: string) => void;
  setStep: (step: ReservationSteps | "error") => void;
  setPaymentSourceId: (id: string | null) => void;
  setPaymentLast4: (last4?: string | null) => void;
  setSelectedShifts: (shifts: Models.BookingConfigSuggestedShift[]) => void;
  nextStep: (options: GoNextOptions) => void;
  setFetching: (fetching: boolean) => void;
  prevStep: () => void;
}

const ReservationStoreContext = React.createContext<
  [ReservationStore, ReservationStoreActions] | null
>(null);

export const ReservationStoreProvider: React.FC<{
  initialState: Partial<ReservationStore>;
}> = ({ children, initialState }) => {
  const [{ user }, { getPaymentMethod, savePaymentSource }] = useUserStore();
  const [{ bookingConfig }] = useDeepDivePanelStore();
  const prevUser = usePrevious(user);
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState<ReservationStore>({
    ...defaultState,
    ...initialState,
  });

  useEffect(() => {
    if (!prevUser && user) {
      void (async () => {
        setFetching(true);
        const newChild = must(await createChild());
        setChild(newChild);
        setFetching(false);
      })();
    }
  }, [user]);

  const getSuggestedShifts = useCallback(
    async (child: Models.Child | null = state.child, setToState = false) => {
      const requestParams: Models.BookingConfigSuggestedShiftsRequest = {};

      if (isNil(child)) {
        throw new Error("Child not defined");
      }

      if (isNil(state.facility)) {
        throw new Error("Facility not defined");
      }

      if (!child.id) {
        requestParams.childBirthday = child.birthday;
      } else {
        requestParams.childId = child.id;
      }

      const {
        data: { data },
      } = await BOOKING_API.bookingConfigsByIdSuggestShifts({
        bookingConfigSuggestedShiftsRequest: requestParams,
        id: state.facility.id,
      });

      if (setToState) {
        setState((prev) => ({
          ...prev,
          suggestedShifts: data,
        }));
      }

      return data;
    },
    [state.child, state.facility],
  );

  const createReservations = useCallback(
    async (child: Models.Child) => {
      try {
        setFetching(true);
        await BOOKING_API.bookingConfigsByIdBook({
          bookingConfigBook: {
            childId: must(child.id),
            // TODO remove start
            contactEmail: "test@mail.com",
            contactName: "Parent Name",
            contactPhone: {
              country: Models.Countries.Us,
              number: "1234567890",
            },
            inviteEmails: [],
            inviteMessage: "",
            isEnrolled: !!state.isChildEnrolled,
            // TODO remove end
            medicalInformation: state.childMedicalInfo || undefined,
            shifts: state.selectedShifts.map((s) => ({
              dateTimeFrom: s.shift.dateTimeFrom,
              dateTimeTo: s.shift.dateTimeTo,
              id: s.shift.id,
              cost: s.cost,
              serviceFee: s.serviceFee,
              processingFee: s.processingFee,
              discount: s.discount,
            })),
          },
          id: must(state.facility?.id),
        });

        goNext();
      } catch (error) {
        const errors = error?.response?.data?.errors;

        if (errors) {
          setState((prev) => ({
            ...prev,
            error: errors[0],
          }));

          goToError();
        } else {
          setState((prev) => ({
            ...prev,
            error: {
              code: "unknown",
              message: "",
            },
          }));

          goToError();
        }
      } finally {
        setFetching(false);
      }
    },
    [state],
  );

  const goToError = () => {
    const params = qs.parse(location.search);

    params.step = "error";

    setState((prev) => ({
      ...prev,
      step: "error",
    }));
    history.push({
      search: qs.stringify(params),
    });
  };

  function goNext(replace = false) {
    const params = qs.parse(location.search);
    const currentStep =
      state.step === null || state.step === "error"
        ? 1
        : STEPS_MAP[state.step] || 1;

    let toNext = 1;

    if (
      currentStep === CHILD_SELECT_STEP_ORDER &&
      bookingConfig?.eligibility !==
        Models.BookingEligibility.AllRequiredMedicalForms &&
      bookingConfig?.eligibility !==
        Models.BookingEligibility.CarePassRequiredMedicalForms
    ) {
      if (user) {
        toNext = 3;
      } else {
        toNext = 2;
      }
    }

    if (
      (currentStep === SHIFT_SELECT_STEP_ORDER &&
        state.selectedShifts[0].serviceFee === 0) ||
      (currentStep === MEDICAL_FORMS_STEP_ORDER && user)
    ) {
      toNext = 2;
    }

    const nextStep = currentStep + toNext;

    if (nextStep > 9) {
      return;
    }

    params.step = REVERSED_STEPS_MAP[nextStep];

    if (replace) {
      history.replace({
        search: qs.stringify(params),
      });
    } else {
      history.push({
        search: qs.stringify(params),
      });
    }
  }

  function goPrev() {
    history.goBack();
  }

  const setSelectedShifts = useCallback(
    (shifts: Models.BookingConfigSuggestedShift[]) => {
      setState((prev) => ({
        ...prev,
        selectedShifts: shifts,
      }));
    },
    [setState],
  );

  const setChild = useCallback(
    (child: Models.Child) => {
      setState((prev) => ({
        ...prev,
        child,
      }));
    },
    [setState],
  );

  const setStep = useCallback(
    (step: ReservationSteps | "error") => {
      setState((prev) => ({
        ...prev,
        step,
      }));
    },
    [setState],
  );

  const setError = useCallback(
    (error: ReservationError | null) => {
      setState((prev) => ({
        ...prev,
        error,
      }));
    },
    [setState],
  );

  const setIsChildEnrolled = useCallback(
    (enrolled: boolean) => {
      setState((prev) => ({
        ...prev,
        isChildEnrolled: enrolled,
      }));
    },
    [setState],
  );

  const setChildMedicalInfo = useCallback(
    (info: string) => {
      setState((prev) => ({
        ...prev,
        childMedicalInfo: info,
      }));
    },
    [setState],
  );

  const setFetching = useCallback(
    (fetching: boolean) => {
      setState((prev) => ({
        ...prev,
        fetching,
      }));
    },
    [setState],
  );

  const setPaymentSourceId = useCallback(
    (id: string | null) => {
      setState((prev) => ({
        ...prev,
        paymentSourceId: id,
      }));
    },
    [setState],
  );

  const setPaymentLast4 = useCallback(
    (last4?: string | null) => {
      setState((prev) => ({
        ...prev,
        paymentLast4: last4,
      }));
    },
    [setState],
  );

  const createChild = useCallback(async () => {
    try {
      const { data } = await CHILDREN_API.childrenCreate({
        child: {
          ...must(state.child),
          medicalInformation: state.childMedicalInfo,
        },
      });
      return data;
    } catch (error) {
      const errors = error?.response?.data?.errors;

      if (errors) {
        setState((prev) => ({
          ...prev,
          error: {
            code: "child_error",
            title: errors[0].title,
          },
        }));
        goToError();
      } else {
        throw error;
      }
    }
  }, [state.child, state.childMedicalInfo, setState]);

  const nextStep = async (options: GoNextOptions) => {
    switch (options.type) {
      case "CHILD_SELECT":
        try {
          setFetching(true);
          const shifts = await getSuggestedShifts(options.child);

          setState((prev) => ({
            ...prev,
            child: options.child,
            childMedicalInfo: options.child.medicalInformation || "",
            selectedShifts: [],
            suggestedShifts: shifts,
          }));

          goNext();
        } finally {
          setFetching(false);
        }

        break;
      case "BOOK":
        if (!state.child?.id) {
          try {
            setFetching(true);
            const newChild = must(await createChild());
            setChild(newChild);
            await createReservations(newChild);
          } finally {
            setFetching(false);
          }
        } else if (state.child?.medicalInformation !== state.childMedicalInfo) {
          try {
            setFetching(true);
            const { data } = await CHILDREN_API.childrenByIdUpdate({
              child: {
                ...state.child,
                medicalInformation: state.childMedicalInfo,
              },
              id: state.child.id,
            });

            setChild(data);
            await createReservations(data);
          } finally {
            setFetching(false);
          }
        } else {
          try {
            setFetching(true);
            await createReservations(state.child);
          } finally {
            setFetching(false);
          }
        }

        break;
      case "PARENT_INFO":
        goNext(true);
        break;
      case "SHIFTS_SELECT":
        goNext();
        break;
      case "INVITATION":
        setState((prev) => ({
          ...prev,
          invitationEmails: options.emails,
        }));
        goNext();
        break;
      case "MEDICAL_INFO":
        goNext();
        break;
      case "PAYMENT_SELECT":
        goNext();
        break;
      case "ELIGIBILITY":
        goNext();
        break;
    }
  };

  const prevStep = () => {
    goPrev();
  };

  return (
    <ReservationStoreContext.Provider
      value={[
        state,
        {
          getSuggestedShifts,
          nextStep,
          prevStep,
          setChild,
          setChildMedicalInfo,
          setError,
          setFetching,
          setIsChildEnrolled,
          setPaymentLast4,
          setPaymentSourceId,
          setSelectedShifts,
          setStep,
        },
      ]}
    >
      {children}
    </ReservationStoreContext.Provider>
  );
};

export const useReservationStore = (): [
  ReservationStore,
  ReservationStoreActions,
] => {
  const store = React.useContext(ReservationStoreContext);

  if (!store) {
    throw new Error(
      "useReservationStore must be used within a ReservationStoreProvider",
    );
  }

  return store;
};
