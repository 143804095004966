import React, { useCallback } from "react";
import isFunction from "lodash/isFunction";

import { Models, BOOKING_API } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { AxiosError } from "axios";

export type TermsSectionKey =
  | "required"
  | "procedures"
  | "teachers"
  | "capacity";

export type ScheduleTabs =
  | Models.WeekDay.Saturday
  | Models.WeekDay.Sunday
  | Models.SessionType.Weekday
  | "required"
  | "procedures"
  | "teachers"
  | "capacity";

export type OpenEditDialogState =
  | {
      type: "ages-served";
    }
  | {
      type: "eligibility";
    }
  | {
      type: "service-time";
      session: Models.Session;
    }
  | {
      type: "activate-session";
      session: Models.Session;
    }
  | {
      type: "deactivate-session";
      session: Models.Session;
    }
  | {
      type: "deactivate-all-sessions";
    }
  | {
      type: "activate-all-sessions";
    }
  | {
      type: "cancellation";
    }
  | {
      type: "discount";
    }
  | {
      type: "reservationsAdvance";
    }
  | {
      type: "serviceActivation";
      sessions: Models.Session[];
    };

export type WeekendCareConfigStore = {
  facility: Models.Facility;
  config: Models.BookingConfig;
  activeScheduleTab: ScheduleTabs;
  editDialogOpened: OpenEditDialogState | null;
  fetching?: boolean;
};

type SaveConfigOptions = {
  onSave?: () => void;
  processErrors?: (errors: ServerError[]) => boolean;
};

type ServerErrorCode =
  | "validation_required"
  | "validation_min_greater_equal_than_required"
  | "validation_invalid_min_date"
  | "validation_max_less_equal_than_required"
  | "validation_is_email"
  | "validation_invalid_weekday"
  | "validation_invalid_min_date"
  | "validation_invalid_zip_code"
  | "validation_length_invalid"
  | "validation_length_out_of_range"
  | "validation_booking_config_conflicts"
  | "validation_invalid_only_datetime"
  | "booking_not_approved";

type ServerError = {
  code: ServerErrorCode;
  source: {
    pointer: string;
  };
  title: string;
  meta?: {
    [key: string]: any;
  };
};

interface WeekendCareConfigStoreActions {
  setConfig: (config: Models.BookingConfig) => void;
  setActiveScheduleTab: (section: ScheduleTabs) => void;
  setEditDialogState: (state: OpenEditDialogState | null) => void;
  saveConfig: (
    config: Models.BookingConfig,
    options?: SaveConfigOptions,
  ) => Promise<void>;
}

const WeekendCareConfigStoreContext = React.createContext<
  [WeekendCareConfigStore, WeekendCareConfigStoreActions] | null
>(null);

export const WeekendCareConfigStoreProvider: React.FC<{
  store: WeekendCareConfigStore;
}> = ({ children, store }) => {
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const [state, setState] = React.useState(() => {
    return {
      ...store,
      activeScheduleTab: (() => {
        const activeSessions = store.config.sessions.filter(
          (s) => s.status === Models.SessionStatus.Active,
        );

        if (
          activeSessions.some(
            (s) =>
              s.shiftType === Models.SessionType.Weekday ||
              s.shiftType === Models.SessionType.Evening,
          )
        ) {
          return Models.SessionType.Weekday;
        }

        if (activeSessions.some((s) => s.weekday === Models.WeekDay.Saturday)) {
          return Models.WeekDay.Saturday;
        }

        if (activeSessions.some((s) => s.weekday === Models.WeekDay.Sunday)) {
          return Models.WeekDay.Sunday;
        }

        return store.activeScheduleTab;
      })(),
    };
  });

  const setEditDialogState = useCallback(
    (state: OpenEditDialogState | null) => {
      setState((prev) => ({
        ...prev,
        editDialogOpened: state,
      }));
    },
    [setState],
  );

  const setActiveScheduleTab = useCallback(
    (section: ScheduleTabs) => {
      setState((prev) => ({
        ...prev,
        activeScheduleTab: section,
      }));
    },
    [setState],
  );

  const setConfig = useCallback(
    (config: Models.BookingConfig) => {
      setState((prev) => ({
        ...prev,
        config,
      }));
    },
    [setState],
  );

  const setFetching = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        fetching: state,
      }));
    },
    [setState],
  );

  const saveConfig = async (
    config: Models.BookingConfig,
    options?: SaveConfigOptions,
  ) => {
    try {
      setFetching(true);
      const { data } = await BOOKING_API.bookingConfigsByIdUpdate({
        bookingConfig: config,
        id: state.facility.id,
      });

      if (isFunction(options?.onSave)) {
        options?.onSave();
      }

      setConfig(data);
    } catch (e) {
      const error: AxiosError<{ errors: ServerError[] }> = e;
      let errorHandled = false;
      const errors = error?.response?.data?.errors;

      const approveError = errors?.find(
        (e) => e.code === "booking_not_approved",
      );

      if (approveError) {
        const { data } = await BOOKING_API.bookingConfigsByIdGet({
          id: state.facility.id,
        });

        setConfig(data);

        localStorage.removeItem(
          `activation-request-submitted-${state.facility.id}`,
        );
        setEditDialogState({
          sessions: [
            config.sessions[
              parseInt(approveError.source.pointer.split("/")[2], 10)
            ],
          ],
          type: "serviceActivation",
        });

        return;
      }

      if (errors) {
        if (
          isFunction(options?.processErrors) &&
          options?.processErrors(errors)
        ) {
          errorHandled = true;
        } else {
          setNotification({
            message: errors[0].title,
            type: "error",
          });
          return;
        }
      }

      if (!errorHandled) {
        setUnknownErrorNotification();
        throw error;
      }
    } finally {
      setFetching(false);
    }
  };

  return (
    <WeekendCareConfigStoreContext.Provider
      value={[
        state,
        {
          saveConfig,
          setActiveScheduleTab,
          setConfig,
          setEditDialogState,
        },
      ]}
    >
      {children}
    </WeekendCareConfigStoreContext.Provider>
  );
};

export const useWeekendCareConfigStore = (): [
  WeekendCareConfigStore,
  WeekendCareConfigStoreActions,
] => {
  const store = React.useContext(WeekendCareConfigStoreContext);

  if (!store) {
    throw new Error(
      "useWeekendCareConfigStore must be used within a WeekendCareConfigStoreProvider",
    );
  }

  return store;
};
