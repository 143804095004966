import React, { useEffect } from "react";
import { StylesProvider } from "@material-ui/styles";
import track from "react-tracking";
import * as Sentry from "@sentry/react";

import { TOKEN_KEY } from "@constants";
import useStyles from "./styles";
import ApplicationInsightsTracker from "@ui-kit/ApplicationInsightsTracker";
import GoogleAnalyticsTracker from "@ui-kit/GoogleAnalyticsTracker";
import ProgressSpinner from "@ui-kit/ProgressSpinner";
import FullstoryTracker from "@ui-kit/FullstoryTracker";
import Snackbar from "@ui-kit/Snackbar";
import { initEnvironment } from "@utils";
import { AppStoreProvider, useAppStore } from "@store/AppStore";
import {
  useNotificationStore,
  NotificationStoreProvider,
} from "@store/NotificationStore";
import { UserStoreProvider } from "@store/UserStore";
import { Captcha } from "@ui-kit/Captcha";
import { ViewportProvider } from "@hooks/useViewport";
import { AppRouting } from "./AppRouting";
import { AmplitudeTracker } from "@ui-kit/AmplitudeTracker";
import { Amplitude } from "@services/amplitude";
import { getErrorStub } from "@utils/getErrorStub";

initEnvironment();

const App: React.FC = () => {
  const [{ notification }, { removeNotification }] = useNotificationStore();
  const [{ loader }] = useAppStore();

  useStyles();

  useEffect(() => {
    window.addEventListener(
      "storage",
      (event) => {
        if (event.key === TOKEN_KEY) {
          window.location.reload();
        }
      },
      false,
    );

    const jssStyles = document.getElementById("jss-server-side");

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <StylesProvider injectFirst>
      <Sentry.ErrorBoundary
        fallback={
          <div
            dangerouslySetInnerHTML={{
              __html: getErrorStub(),
            }}
          />
        }
      >
        <div className="App">
          <FullstoryTracker />
          <ApplicationInsightsTracker />
          <GoogleAnalyticsTracker />
          <AmplitudeTracker />
          <AppRouting />
          {loader && <ProgressSpinner />}
          {notification && (
            <Snackbar
              anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              open
              type={notification.type}
              autoHideDuration={5000}
              onClose={removeNotification}
              message={notification.message}
            />
          )}
          <Captcha />
        </div>
      </Sentry.ErrorBoundary>
    </StylesProvider>
  );
};

const WrappedApp: React.FC = () => (
  <ViewportProvider>
    <AppStoreProvider>
      <UserStoreProvider>
        <NotificationStoreProvider>
          <App />
        </NotificationStoreProvider>
      </UserStoreProvider>
    </AppStoreProvider>
  </ViewportProvider>
);

WrappedApp.displayName = "App";

export default track(
  {},
  {
    dispatch: ({ page, action, ...rest }) => {
      let amplitudeAction = action;

      if (page) {
        amplitudeAction = `${page}: ${amplitudeAction}`;
      }
      Amplitude?.logEvent(amplitudeAction, rest);
    },
  },
)(WrappedApp);
