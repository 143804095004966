import { StyleSheet, Rule } from "jss";

export const createGenerateId =
  () =>
  (rule: Rule, sheet?: StyleSheet): string => {
    const processId = (id: string): string => {
      if (/-[0-9]*$/.exec(id)) {
        return processId(id.slice(0, id.length - 1));
      }

      return id;
    };
    let prefix = sheet?.options.classNamePrefix || "";

    if (!prefix?.endsWith("-")) {
      prefix += "-";
    }

    return processId(prefix + rule.key);
  };
