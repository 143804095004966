import React from "react";
import clsx from "clsx";
import MaterialTypography, {
  TypographyProps as MaterialTypographyProps,
} from "@material-ui/core/Typography";
import omit from "lodash/omit";

import { theme } from "@theme";
import { TypographyProps } from "./types";
import { useCss } from "./styles";

const Typography: React.FC<TypographyProps> = ({
  italic,
  classes,
  bolded,
  paragraphTop,
  children,
  gap,
  color = "initial",
  ...props
}) => {
  const css = useCss();

  const omitted = omit(props, [
    "textSize",
    "required",
  ]) as MaterialTypographyProps;
  const appliedColor =
    color === "success" || color === "error" || color === "warning"
      ? undefined
      : color;

  return (
    <MaterialTypography
      {...omitted}
      color={appliedColor}
      css={css.root}
      classes={{
        ...classes,
        root: clsx(classes?.root, {
          bolded,
          gap,
          italic,
          paragraphTop,
          [color]: true,
        }),
      }}
    >
      {children}
      {props.required && (
        <span style={{ color: theme.palette.error.main }}> *</span>
      )}
    </MaterialTypography>
  );
};

Typography.defaultProps = {
  bolded: false,
  italic: false,
};

export default Typography;
